import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../utility/util';
import { ApiUtilService } from '../service/api-util.service';
import { CommonService } from '../service/common.service';
import { DefaultTextCellRendererComponent } from '../data-grid/default-text-cell-renderer.component';
import { EventSchemaListActionsComponent } from '../data-grid/event-schema-list-actions.component';
import { DateTimeRendererComponent } from '../data-grid/date-time-renderer.component';
import { apiProperties } from '../utility/constants';
declare var $: any;

@Component({
  selector: 'app-rule-events-list',
  templateUrl: './rule-events-list.component.html',
  styleUrls: ['./rule-events-list.component.css']
})
export class RuleEventsListComponent implements OnInit {

  public ruleEventsList: any = [];
  public columnDefs: any[];
  public context: any;
  public isSubmodule: boolean;
  public frameworkComponents: object;
  private gridApi: any;
  public pageSize: any = 10;
  public selectedRowObject:any = {};
  recommendationCategoryMap: any = {};

  constructor(
    private apiUtilService: ApiUtilService,
    private toastr: ToastrService,
    private router: Router,
    private cs: CommonService
  ) { }

  ngOnDestroy(){
    $('#delete-confirmation-modal').modal('hide');
    $('#clone-rule-event-modal').modal('hide');
  }

  ngOnInit() {
    document.title = "CCS - Rule Events";
    this.isSubmodule = this.apiUtilService.routedSubmoduleName != "";
    this.initDataGridDetails();
    this.getAllRuleEvents();
    this.getAllCategoryList();
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: 'Name', field: 'name', sortable: true, suppressMovable: true, cellClass: 'text-custom-link',cellRenderer:'defaultTextCellRendererComponent' },
      { headerName: 'Category', field: 'category', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color',cellRenderer:'defaultTextCellRendererComponent'  },
      { headerName: 'Created', field: 'createdAt', sortable: true, suppressMovable: true, width: 120, cellClass: 'ag-text-val-color',cellRenderer:'dateTimeRendererComponent'  },
      { headerName: 'Updated', field: 'updatedAt', sortable: true, suppressMovable: true, width: 120, cellClass: 'ag-text-val-color',cellRenderer:'dateTimeRendererComponent'  },
      { headerName: 'Actions', sortable: false, suppressMovable: true, width: 115, cellClass: 'p-0', options: { isSubmodule: this.isSubmodule } ,cellRenderer:'eventSchemaListActionsComponent'}
    ];
    this.frameworkComponents = {
      defaultTextCellRendererComponent: DefaultTextCellRendererComponent,
      eventSchemaListActionsComponent: EventSchemaListActionsComponent,
      dateTimeRendererComponent: DateTimeRendererComponent
    }
  }


  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.cs.updateCurrentGridAPI(this.gridApi);
  }

  onFilterTextBoxChanged(searchText: string) {
    this.gridApi.setQuickFilter(searchText);
  }

  getAllRuleEvents() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_RECOMMENDATION_RULE_EVENTS;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.ruleEventsList = res.body.items || [];
        Utils.loader('#page-loader', 'hide');
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.log(err);
      }
    );
  }

  invokeGridAction(params: any) {
    let currentRowData: object = params.rowData;
    let type: string = params.type;
    if(type === 'edit'){
      if (this.isSubmodule) this.router.navigate(['landing', { outlets: { studio: `submodule/${this.apiUtilService.routedSubmoduleName}/rule-events-studio/${currentRowData['name']}` }}]);
      else this.router.navigate(['landing', { outlets: { studio: `rule-events-studio/${currentRowData['name']}` }}]);
    } 
    else if (type === 'delete') {
      this.selectedRowObject = currentRowData;
      $('#delete-confirmation-modal').modal('show');
    } 
    else if (type === 'clone') {
      this.selectedRowObject = JSON.parse(JSON.stringify(currentRowData));
      this.selectedRowObject.updatedName = this.selectedRowObject.name + "_Clone";
      this.selectedRowObject.updatedDisplayName = this.selectedRowObject.displayName ? (this.selectedRowObject.displayName + " Clone") : this.selectedRowObject.updatedName;
      this.loadExecutesAtData();
     $('#clone-rule-event-modal').modal('show');
    }
  }

  cloneRuleEvent(edit?){
    try{
      this.selectedRowObject.updatedName =  this.selectedRowObject.updatedName.replace(/\s+$/, '');
      if (!this.selectedRowObject.updatedName) throw 'Rule Event Name cannot be empty';
      let isExist = this.ruleEventsList.find((ruleEvent: any) => ruleEvent.name.toLowerCase() === this.selectedRowObject.updatedName.toLowerCase());
      if(isExist) throw 'Rule Event Name already exists';

      Utils.loader('#page-loader', 'show');
      let currentAPIProperty = apiProperties.CLONE_RECOMMENDATION_RULE_EVENT;
      let reqBody = {
        "name": this.selectedRowObject.updatedName,
        "displayName": this.selectedRowObject.updatedDisplayName, 
        "category": this.selectedRowObject.category,
        "executesAt": this.selectedRowObject.executesAt
      }
      this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.selectedRowObject.name), currentAPIProperty.method, reqBody).subscribe(
        (res: any) => {
          $('#clone-rule-event-modal').modal('hide');
          Utils.loader('#page-loader', 'hide');
          if(edit) this.invokeGridAction({rowData: res.body, type: 'edit'});
          else this.getAllRuleEvents();
          this.toastr.success("Rule Event Cloned Successfully.");
        },
        (err: any) => {
          this.toastr.error((err.error || {}).message || 'Rule Event (' + this.selectedRowObject.name + ') Clone Failed');
          Utils.loader('#page-loader', 'hide');
          console.log(err);
        }
      );
    }catch(error){
      this.toastr.error(error);
    }
  }

  deleteRuleEvent(){
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.DELETE_RECOMMENDATION_RULE_EVENT_BY_NAME;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.selectedRowObject.name), currentAPIProperty.method).subscribe(
      (res: any) => {
        this.toastr.success('Rule Event (' + this.selectedRowObject.name + ') Deleted Successfully');
        $('#delete-confirmation-modal').modal('hide');
        Utils.loader('#page-loader', 'hide');
        this.getAllRuleEvents();
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Rule Event (' + this.selectedRowObject.name + ') Delete Failed');
        Utils.loader('#page-loader', 'hide');
        console.log(err);
      }
    );
  }

  getAllCategoryList() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_RECOMMENDATION_CATEGORY_LIST;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.recommendationCategoryMap = {};
        (res.body || []).forEach((c: any) => this.recommendationCategoryMap[c.name] = c );
        Utils.loader('#page-loader', 'hide');
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.log(err);
      }
    );
  }

  loadExecutesAtData() {
    this.selectedRowObject.executesAt = ((this.recommendationCategoryMap[this.selectedRowObject.category] || {}).executesAt || []);
  }

}
