import { Component } from "@angular/core";
import { Utils } from "./../utility/util";
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'api-method-badge-cell',
    template: ` 
                 <div class="d-flex mt-1" style="margin-left: 1.8rem;">
                    <label class="sla-chip-active" *ngIf="status">Active</label>
                    <label class="sla-chip-inactive" *ngIf="!status">Inactive</label>
                </div>
                `,
    styles: []
})
export class RuleTemplateStatusBadgeRenderer implements ICellRendererAngularComp {
    public status : boolean

    constructor() {}

    agInit(params: any): void {
        const data: any = params.value;
        if(data === 'Active')
            this.status = true;
        else
            this.status = false;
    }

    refresh(): boolean {
        return false;
    }
}