import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl, ValidationErrors } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtHelperService, JWT_OPTIONS   } from '@auth0/angular-jwt';

import { FormlyModule } from "@ngx-formly/core";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';

import { FormlyFieldStepper } from './formly/stepper.type';
import { FormlyFieldButton } from './formly/button-type.component';
import { FormlyFieldAuthorizeButton } from './formly/authorize-button.component';
import { FormlyFieldFile } from './formly/formly-field-file';
import { FormlyCustomIframe } from './formly/custom-iframe.component';
import { FormlyRefreshButton } from './formly/refresh-button.component';
import { RepeatSectionComponent } from './formly/repeat-section.component'
import { CustomLabelComponent } from './formly/custom-label.component';
import { RowButtonComponent } from './formly/row-button.component';
import { AutoRefreshComponent } from './formly/auto-refresh.component';
import { ShowSpinnerComponent } from './formly/show-spinner.component';
import { FormlyFieldTabs } from './formly/tabs.type';
import { FormlyFieldDatepicker } from './formly/material/formly-field-datepicker.component';
import { CustomDatelabelComponent } from './formly/custom-datelabel.component';
import { CustomInputComponent } from './formly/custom-input.component';
import { FormlyFieldToggle } from './formly/material/formly-field-toggle.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FormlyFieldNgxSlider } from './formly/formly-field-ngx-slider.component';

import { PipesModule } from './pipes/pipes.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ContextMenuModule } from 'ngx-contextmenu';
import { ToastrModule } from 'ngx-toastr';
import { OrderModule } from 'ngx-order-pipe';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';

import { AppRoutingModule } from './app-routing.module';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { AgGridModule } from 'ag-grid-angular';
import { DragDropModule  } from '@angular/cdk/drag-drop';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ColorPickerModule } from 'ngx-color-picker';

import { AppComponent } from './app.component';
import { CustomRouterHandlerComponent } from './platform/custom-router-handler/custom-router-handler.component';
import { ProcessListComponent } from './process-list/process-list.component';
import { ProcessStudioCanvasComponent, OperationFilterPipe } from './process-studio-canvas/process-studio-canvas.component';
import { DefaultTextCellRendererComponent } from './data-grid/default-text-cell-renderer.component';
import { InternalFlowListActionsComponent } from './data-grid/internal-flow-list-actions.component';
import { ApiMethodBadgeRendererComponent } from './data-grid/api-method-badge-renderer.component';
import { BulkAPICreateCellRendererComponent } from './data-grid/bulk-api-create-cell-renderer.component';
import { DateTimeRendererComponent } from './data-grid/date-time-renderer.component';
import { ProcessListActionsComponent } from './data-grid/process-list-actions.component';
import { ProcessListPublicComponent } from './data-grid/process-list-public.component';
import { EventApiListActionsComponent } from './data-grid/event-api-list-actions.component';
import { EventSchemaListActionsComponent } from './data-grid/event-schema-list-actions.component';
import {RuleTemplateListActionsComponent} from './data-grid/rule-template-list-actions.component';
import { ApiConnectorListActionsComponent } from './data-grid/api-connector-list-actions.component';
import { ObjectModelerListActionsComponent } from './data-grid/object-modeler-list-actions.component';
import { UserListActionsComponent } from './data-grid/user-list-actions.component';
import { ObjectAttributeCellRendererComponent } from './data-grid/object-attr-cell-renderer.component';
import { ObjectRelationshipCellRendererComponent } from './data-grid/object-rels-cell-renderer.component';
import { SystemPropertiesListActionComponent } from './data-grid/system-properties-list-actions.component';
import { SystemPropertiesIssecuredCellRendererComponent } from './data-grid/system-properties-issecured-cell-renderer.component.1';
import { AnalyticsListActions } from './data-grid/analytics-list-actions.component';
import { AnalyticsTypeComponent } from './data-grid/analytics-type.component';

import { Utils } from './../app/utility/util';
import { EventApiSettingsComponent } from './event-api-settings/event-api-settings.component';
import { EventApiListComponent } from './event-api-list/event-api-list.component';
import { EventSchemaListComponent } from './event-schema-list/event-schema-list.component';
import { EventApiStudioComponent } from './event-api-studio/event-api-studio.component';
import { EventSchemaStudioComponent } from './event-schema-studio/event-schema-studio.component';
import { ProcessOrchestrationLandingComponent } from './process-orchestration-landing/process-orchestration-landing.component';
import { DeleteConfirmationModalComponent } from './utility/modals/delete-confirmation-modal/delete-confirmation-modal.component';
import { ObjectModelerListComponent } from './object-modeler-list/object-modeler-list.component';
import { ObjectModelerEditorComponent } from './object-modeler-editor/object-modeler-editor.component';
import { ApiConnectorListComponent } from './api-connector-list/api-connector-list.component';
import { ApiConnectorConfigurationComponent } from './api-connector-configuration/api-connector-configuration.component';
import { SystemPropertiesListComponent } from './system-properties-list/system-properties-list.component';
import { SystemPropertiesEditorComponent } from './system-properties-editor/system-properties-editor.component';
import { LoginComponent } from './login/login.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { InternalFlowListComponent } from './internal-flow-list/internal-flow-list.component';
import { InternalFlowStudioComponent } from './internal-flow-studio/internal-flow-studio.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { defineLordIconElement } from 'lord-icon-element';
import lottie from 'lottie-web';
import { ImportApiComponent } from './import-api/import-api.component';
import { InternalFlowCreateModalComponent } from './internal-flow-create-modal/internal-flow-create-modal.component';
import { ApiConnectionConfigurationComponent } from './api-connection-configuration/api-connection-configuration.component';
import { RecentlyEditedComponent } from './recently-edited/recently-edited.component';
import { SvgImagesComponent } from './svg-images/svg-images.component';
import { LandingGridSearchBarComponent } from './landing-grid-search-bar/landing-grid-search-bar.component';
import { AnalyticsListComponent } from './analytics-list/analytics-list.component';
import { AnalyticsStudioComponent } from './analytics-studio/analytics-studio.component';
import { DataSourceComponent } from './analytics-studio/data-source/data-source.component';
import { ChartPropertiesComponent } from './analytics-studio/chart-properties/chart-properties.component';
import { DataFiltersComponent } from './analytics-studio/data-filters/data-filters.component';
import { ChartTypesComponent } from './analytics-studio/chart-types/chart-types.component';
import { ReportsModule} from './reports/reports.module';
import { AnalyticsCreateWizardComponent } from './analytics-list/analytics-create-wizard/analytics-create-wizard.component';
import { AnalyticsPreviewComponent } from './analytics-studio/analytics-preview/analytics-preview.component';
import { ChartDatatableComponent } from './analytics-studio/chart-datatable/chart-datatable.component';
import { QuickAccessOrchestrationLandingComponent } from './quick-access-orchestration-landing/quick-access-orchestration-landing.component';
import { MonitorLogsComponent } from './monitor-logs/monitor-logs.component';
import { MonitorLogsActionsComponent } from './data-grid/monito-logs-actions.component';
import { ProcessLogComponent } from './monitor-logs/process-log/process-log.component';
import { MonitorLogsDateTimeRendererComponent } from './data-grid/monitor-logs-date-time-renderer.component';
import { UserListComponent } from './user-management/users/user-list/user-list.component';
import { UsersCreateComponent } from './user-management/users/users-create/users-create.component';
import { RuleEventsListComponent } from './rule-events-list/rule-events-list.component';
import { RuleTemplatesListComponent } from './rule-templates-list/rule-templates-list.component';
import { RuleEventsStudioComponent } from './rule-events-studio/rule-events-studio.component';
import { RuleTemplateStudioComponent } from './rule-template-studio/rule-template-studio.component';
import { RuleTemplateStatusBadgeRenderer } from './data-grid/rule-template-status-badge-renderer.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { RuleTemplateStudioObjectsComponent } from './rule-template-studio-objects/rule-template-studio-objects.component';

@NgModule({
  declarations: [
    FormlyFieldStepper,
    FormlyFieldButton,
    FormlyFieldAuthorizeButton,
    FormlyFieldFile,
    FormlyCustomIframe,
    FormlyRefreshButton,
    RepeatSectionComponent,
    CustomLabelComponent,
    RowButtonComponent,
    AutoRefreshComponent,
    ShowSpinnerComponent,
    FormlyFieldTabs,
    FormlyFieldDatepicker,
    CustomInputComponent,
    CustomDatelabelComponent,
    FormlyFieldToggle,
    FormlyFieldNgxSlider,
    AppComponent,
    CustomRouterHandlerComponent,
    ProcessListComponent,
    ProcessStudioCanvasComponent,
    DefaultTextCellRendererComponent,
    ApiMethodBadgeRendererComponent,
    RuleTemplateStatusBadgeRenderer,
    BulkAPICreateCellRendererComponent,
    DateTimeRendererComponent,
    ProcessListActionsComponent,
    ProcessListPublicComponent,
    EventApiListActionsComponent,
    InternalFlowListActionsComponent,
    EventSchemaListActionsComponent,
    RuleTemplateListActionsComponent,
    ObjectModelerListActionsComponent,
    UserListActionsComponent,
    ObjectAttributeCellRendererComponent,
    ObjectRelationshipCellRendererComponent,
    EventApiSettingsComponent,
    EventApiListComponent,
    EventSchemaListComponent,
    ApiConnectorListActionsComponent,
    SystemPropertiesListActionComponent,
    SystemPropertiesIssecuredCellRendererComponent,
    EventApiStudioComponent,
    EventSchemaStudioComponent,
    ProcessOrchestrationLandingComponent,
    DeleteConfirmationModalComponent,
    OperationFilterPipe,
    ObjectModelerListComponent,
    ObjectModelerEditorComponent,
    ApiConnectorListComponent,
    ApiConnectorConfigurationComponent,
    SystemPropertiesListComponent,
    SystemPropertiesEditorComponent,
    LoginComponent,
    UnauthorizedComponent,
    InternalFlowListComponent,
    InternalFlowStudioComponent,
    ImportApiComponent,
    InternalFlowCreateModalComponent,
    ApiConnectionConfigurationComponent,
    RecentlyEditedComponent,
    SvgImagesComponent,
    LandingGridSearchBarComponent,
    AnalyticsListComponent,
    AnalyticsStudioComponent,
    AnalyticsListActions,
	  DataSourceComponent,
    ChartPropertiesComponent,
    DataFiltersComponent,
    AnalyticsTypeComponent,
    ChartTypesComponent,
    AnalyticsCreateWizardComponent,
    AnalyticsPreviewComponent,
    ChartDatatableComponent,
    QuickAccessOrchestrationLandingComponent,
    MonitorLogsComponent,
    MonitorLogsActionsComponent,
    ProcessLogComponent,
    MonitorLogsDateTimeRendererComponent,
    UserListComponent,
    UsersCreateComponent,
    RuleEventsListComponent,
    RuleTemplatesListComponent,
    RuleEventsStudioComponent,
    RuleTemplateStudioComponent,
    RuleTemplateStudioObjectsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    FormlyModule.forRoot({
      validators: [
        { name: 'email', validation: emailValidator },
        { name: 'domain', validation: domainValidator }
        ],
      validationMessages: [
        { name: "required", message: "This field is required" },
        { name: 'email', message: "This not a valid email Address" },
        { name: 'domain', message: "This not a valid domain" }
      ],
      types: [
        {
          name: "stepper",
          component: FormlyFieldStepper,
          wrappers: ["form-field"]
        },
        {
          name: "button",
          component: FormlyFieldButton,
          wrappers: ["form-field"],
          defaultOptions: {
            templateOptions: {
              btnType: "default",
              type: "button"
            }
          }
        },
        {
          name: "ms-authorize",
          component: FormlyFieldAuthorizeButton,
          wrappers: ["form-field"],
          defaultOptions: {
            templateOptions: {
              btnType: "info",
              type: "button"
            }
          }
        },
        {
          name: "file",
          component: FormlyFieldFile,
          wrappers: ["form-field"]
        },
        {
          name: 'custom-iframe',
          component: FormlyCustomIframe,
          wrappers: ["form-field"]
        },
        {
          name: 'refresh-button',
          component: FormlyRefreshButton,
          wrappers: ["form-field"]
        },
        { name: "repeat", component: RepeatSectionComponent },
        { name: "label", component: CustomLabelComponent },
        { name: "row-button", component: RowButtonComponent },
        { name: "auto-refresh", component: AutoRefreshComponent },
        { name: "show-spinner", component: ShowSpinnerComponent },
        {
          name: "tabs",
          component: FormlyFieldTabs,
          wrappers: ["form-field"]
        },
        {
          name: 'datepicker',
          component: FormlyFieldDatepicker
        },
        { 
          name: 'datetimelabel', 
          component: CustomDatelabelComponent 
        },
        {
          name: 'custom-input',
          component: CustomInputComponent
        },
        {
          name: 'toggle',
          component: FormlyFieldToggle,
          wrappers: ["form-field"]
        },
        {
          name: 'ngx-slider',
          component: FormlyFieldNgxSlider,
          wrappers: ["form-field"]
        }
      ]
    }),
    MatStepperModule,
    MatIconModule,
    MatTabsModule,
    MatTreeModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSelectModule,
    NgxSliderModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    PipesModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxJsonViewerModule,
    CodemirrorModule,
    OrderModule,
    ReportsModule,
    ClipboardModule,
    AgGridModule.withComponents([]),
    PopoverModule.forRoot(),
    ColorPickerModule,
    ContextMenuModule.forRoot(),
    NgbModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      timeOut: 3000
    }),
    HttpClientModule,
    DragDropModule,
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    Utils,
    NgbModule,
    ClipboardService
  ],
  entryComponents: [
    DefaultTextCellRendererComponent, 
    ProcessListActionsComponent, 
    ProcessListPublicComponent,
    EventApiListActionsComponent, 
    InternalFlowListActionsComponent,
    ApiMethodBadgeRendererComponent,
    RuleTemplateStatusBadgeRenderer,
    BulkAPICreateCellRendererComponent,
    DateTimeRendererComponent,
    EventSchemaListActionsComponent, 
    RuleTemplateListActionsComponent,
    ApiConnectorListActionsComponent,
    SystemPropertiesListActionComponent,
    SystemPropertiesIssecuredCellRendererComponent,
    ObjectModelerListActionsComponent,
    UserListActionsComponent,
    ObjectAttributeCellRendererComponent,
    ObjectRelationshipCellRendererComponent,
    AnalyticsTypeComponent,
    AnalyticsListActions,
    MonitorLogsActionsComponent,
    MonitorLogsDateTimeRendererComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() {
    defineLordIconElement(lottie.loadAnimation);
  }
}
export function emailValidator(control: FormControl): ValidationErrors {
  return !control.value || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(control.value) ? null as any : { 'email': true };
}

export function domainValidator(control: FormControl): ValidationErrors {
  return !control.value || /^\S*$/.test(control.value) ? null as any : { 'domain': true };
}
