import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from './../utility/util';
import { CommonService } from '../service/common.service';
import { ApiUtilService } from '../service/api-util.service';
import { ObjectModelerService } from './../service/object-modeler.service';
import { DefaultTextCellRendererComponent } from './../data-grid/default-text-cell-renderer.component';
import { DateTimeRendererComponent } from './../data-grid/date-time-renderer.component';
import { ObjectModelerListActionsComponent } from './../data-grid/object-modeler-list-actions.component';
import { apiProperties } from '../utility/constants';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'ccp-object-modeler-list',
  templateUrl: './object-modeler-list.component.html',
  styleUrls: ['./object-modeler-list.component.css']
})
export class ObjectModelerListComponent implements OnInit {

  private gridApi: any;
  public pageSize: any = 10;
  public columnDefs: any[];
  public context: any;
  public frameworkComponents: object;

  public isSubmodule: boolean;
  public selectedRowObject: any= {};
  public isObjectNameExists_ignoreCase: boolean = false;
  public isInValidCloneObj: boolean = false;

  constructor(
    public oms: ObjectModelerService,
    public apiUtilService: ApiUtilService,
    private router: Router,
    private toastr: ToastrService,
    private cs: CommonService
  ) { }

  ngOnInit() {
    document.title = "CCS - Objects";
    this.isSubmodule = this.apiUtilService.routedSubmoduleName != "";
    this.initDataGridDetails();
    this.getAllObjects();
  }

  getAllObjects() {
    Utils.loader('#page-loader', 'show');
    this.oms.getAllObjects((res: any) => {
      Utils.loader('#page-loader', 'hide');
    }, (err: any) => {
      console.error(err);
      Utils.loader('#page-loader', 'hide');
    });
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: "Object Name", field: 'model', sortable: true, suppressMovable: true, cellClass: 'text-custom-link' },
      { headerName: 'Is Persistent Object', field: 'isPersistentObject', width: 130, sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color ag-text-align-center'},
      { headerName: 'Summary', field: 'summary', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Created', field: 'createdAt', sortable: true, suppressMovable: true, width: 100, cellClass: 'ag-text-val-color' },
      { headerName: 'Updated', field: 'updatedAt', sortable: true, suppressMovable: true, width: 100, cellClass: 'ag-text-val-color' },
      { headerName: 'Actions', sortable: false, suppressMovable: true, width: 115, cellClass: 'p-0', options: { isSubmodule: this.isSubmodule } }
    ];
    this.frameworkComponents = {
      defaultTextCellRendererComponent: DefaultTextCellRendererComponent,
      objectModelerListActionsComponent: ObjectModelerListActionsComponent,
      dateTimeRendererComponent: DateTimeRendererComponent
    }
    this.setDataGridColDefFormatters();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.cs.updateCurrentGridAPI(this.gridApi);
  }

  onFilterTextBoxChanged(searchText: string) {
    this.gridApi.setQuickFilter(searchText);
  }

  setDataGridColDefFormatters() {
    this.columnDefs.forEach((colDef: any) => {
      if (!colDef.valueFormatter) {
        const headerName: string = colDef.headerName;
        switch (headerName) {
          case 'Created':
          case 'Updated':
            colDef.cellRenderer = 'dateTimeRendererComponent';
            break;
          case 'Is Persistent Object':
            colDef.valueFormatter = isPersistentObjectValueFormatter; 
            break;
          case 'Actions':
            colDef.cellRenderer = 'objectModelerListActionsComponent';
            break;
          default:
            colDef.cellRenderer = 'defaultTextCellRendererComponent';
            break;
        }
      }
    });
  }

  invokeGridAction(params: any) {
    let currentRowData: object = params.rowData;
    let type: string = params.type;
    if (type === 'edit') {
      if (this.isSubmodule) this.router.navigate(['landing', { outlets: { studio: `submodule/${this.apiUtilService.routedSubmoduleName}/object-modeler-editor/${currentRowData['id']}` }}]);
      else this.router.navigate(['landing', { outlets: { studio: `object-modeler-editor/${currentRowData['id']}` }}]);
    } else if (type === 'delete') {
      this.selectedRowObject = currentRowData;
      $('#delete-confirmation-modal').modal('show');
    } else if (type == 'clone') {
      this.selectedRowObject = JSON.parse(JSON.stringify(currentRowData));
      this.selectedRowObject.model = this.selectedRowObject.model + "Clone";
      if(this.selectedRowObject.summary) this.selectedRowObject.summary = this.selectedRowObject.summary + " Clone";
      $('#clone-object-modal').modal('show');
    }
  }

  cloneObject(navToEdit?: boolean) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.CLONE_APPLICATION_OBJECT_METADATA;
    var { model, summary } = this.selectedRowObject;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", this.selectedRowObject.id), currentAPIProperty.method, { model, summary }).subscribe(
      (res: any) => {
        $('#clone-object-modal').modal('hide');
        Utils.loader('#page-loader', 'hide');
        if(navToEdit) this.invokeGridAction({rowData: res.body, type: 'edit'});
        else this.getAllObjects();
        this.toastr.success("Object Cloned Successfully.");
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        console.log(err);
      }
    );
  }

  validateModelName(curModelName: string){
    var objectModels = this.oms.objectsList;
    var objectModelsLowerCase = (curModelName || "").toLowerCase();
    var matchedObject = objectModels.find((objects: any) => objects.model == curModelName );
    var matchedObjectIgnoreCase = objectModels.find((objects: any) => (objects.model || "").toLowerCase() == objectModelsLowerCase );
    if(curModelName == ""){
      this.toastr.error('Field cannot be empty');
      this.isInValidCloneObj = true;
      this.isObjectNameExists_ignoreCase = false;
      return;
    } else if(/^[a-zA-Z0-9_$@]*$/.test(curModelName) == false) {
      this.toastr.error('Remove white space or special characters except "_", "$", "@"');
      this.isInValidCloneObj = true;
      this.isObjectNameExists_ignoreCase = false;
      return;
    } else if(matchedObject){
      this.toastr.error('Object Name already exists');
      this.isInValidCloneObj = true;
      this.isObjectNameExists_ignoreCase = false;
      return;
    } else if(matchedObjectIgnoreCase){
      this.isInValidCloneObj = false;
      this.isObjectNameExists_ignoreCase = true;
      return;
    } else {
      this.isInValidCloneObj = false;
      this.isObjectNameExists_ignoreCase = false;
      return;
    }
  }

  openImportObjectFile () {
    $("#importObjMetaData").click();
  }

  importSwaggerObjMetaData(event: Event) {
    if ($('#importObjMetaData').val() == "") return;
    Utils.loader('#page-loader', 'show');
    var input: any = event.target;
    var reader = new FileReader();
    reader.onload = () => {
      var jsonDATA: any = reader.result;
      var fileName = $('#importObjMetaData').val().split("\\")[2];
      var findExt = /[^\\]*\.(\w+)$/;
      findExt = fileName.match(findExt);
      var extension = findExt[1];
      switch (extension) {
        case "json":
          if (this.isValidSwaggerFileContent(jsonDATA)) {
            let currentAPIProperty = apiProperties.IMPORT_OBJECT_METADATA;
            this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, jsonDATA).subscribe(
              (res: any) => {
                Utils.loader('#page-loader', 'hide');
                this.getAllObjects();
                this.toastr.success("Object Imported Successfully.");
              },
              (err: any) => {
                Utils.loader('#page-loader', 'hide');
                console.log(err);
              }
            );
          } else Utils.loader('#page-loader', 'hide');
          break;
        case "yml":
          //TODO:
          break;
        default:
          this.toastr.error("Unsupported file format.");
          Utils.loader('#page-loader', 'hide');
      }
      $('#importObjMetaData').val("");
    };
    reader.readAsText(input.files[0]);
  }

  isValidSwaggerFileContent(swaggerFileContent: string) {
    try {
      var data = JSON.parse(swaggerFileContent);
      if (data.swagger && data.schemes && data.paths && data.definitions && data.info && data.info.title) {
        return true;
      } else throw "Invalid File";
    } catch (e) {
      this.toastr.error("Invalid file.");
    }
    return false;
  }

}

function isPersistentObjectValueFormatter(params: any){
  const data: any = params.value;
  return data === true ? 'YES' : 'NO';
}
