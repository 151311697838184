import { Component } from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'event-template-list-action-cell',
    template: `<div class="row d-flex w-100 position-absolute action-icon-list-4 m-0 t-0" style="line-height: 55px;" *ngIf="!isSubmodule">
                    <div class="col-3 left-icon text-center cursor-pointer" (click)="invokeAction('edit')">
                        <img class="form-control-img" src="assets/images/actions/edit.svg"/>
                        <div class="left-icon-with-label" style="background-color: #477ED8;">
                            <img class="form-control-img" src="assets/images/actions/edit-white.svg"/>
                            <label class="label-primary-sm float-right pt-3 w-50 cursor-pointer">Edit</label>
                        </div>
                    </div>
                    <div  *ngIf="status != 'Active'" class="col-3 center-icon1 text-center cursor-pointer" (click)="invokeAction('activate')">
                        <img class="form-control-img" src="assets/images/rule-template/Activate_Icon.svg"/>
                        <div class="center-icon-with-label" style="background-color: #238823;">
                            <label class="label-primary-sm float-right pt-3 w-50 cursor-pointer">Activate</label>
                            <img class="form-control-img" src="assets/images/rule-template/Activate_Icon_white.svg"/>
                        </div>
                    </div>
                    <div *ngIf="status == 'Active'" class="col-3 center-icon1 text-center cursor-pointer" (click)="invokeAction('deactivate')">
                        <img class="form-control-img" src="assets/images/rule-template/Inactivate_Icon.svg"/>
                        <div class="center-icon-with-label" style="background-color: #707070;">
                            <label class="label-primary-sm float-right pt-3 w-50 cursor-pointer">Deactivate</label>
                            <img class="form-control-img" src="assets/images/rule-template/Inactivate_Icon_white.svg"/>
                        </div>
                    </div>
                    <div class="col-3 center-icon2 text-center cursor-pointer" (click)="invokeAction('clone')">
                        <i class="fa fa-lg fa-clone font-weight-bold text-dark" aria-hidden="true"></i>
                        <div class="center-icon-with-label" style="background-color: #fb9500;">
                            <i class="fa fa-lg fa-clone font-weight-bold text-white" aria-hidden="true"></i>
                            <label class="label-primary-sm float-right pt-3 w-50 cursor-pointer">Clone</label>
                        </div>
                    </div>
                    <div class="col-3 right-icon text-center cursor-pointer" (click)="invokeAction('delete')">
                        <img class="form-control-img" src="assets/images/actions/delete.svg"/>
                        <div class="right-icon-with-label" style="background-color: #FF7979;">
                            <label class="label-primary-sm pt-3 w-50 float-left cursor-pointer">Delete</label>
                            <img class="form-control-img" src="assets/images/actions/delete-white.svg"/>
                        </div>
                    </div>
                </div>

                <div class="row d-flex w-100 position-absolute action-icon-list-3 m-0 t-0" style="line-height: 55px;" *ngIf="isSubmodule"> 
                    <div class="col-4"></div>
                    <div class="col-4 center-icon text-center cursor-pointer" (click)="invokeAction('edit')">
                        <i class="fa fa-2 fa-eye position-relative t-3" aria-hidden="true"></i>
                        <div class="center-icon-with-label" style="background-color: #477ED8;">
                            <i class="fa fa-2 fa-eye position-relative t-3" aria-hidden="true"></i>
                            <label class="label-primary-sm float-right pt-3 w-50 cursor-pointer">View</label>
                        </div>
                    </div>
                    <div class="col-4"></div>
                </div>`,
    styles: []
})
export class RuleTemplateListActionsComponent implements ICellRendererAngularComp {
    private params: any;
    public isSubmodule: boolean;
    public status:string;

    agInit(params: any): void {
        this.params = params;
        this.isSubmodule = params.isSubmodule;
        this.status = params.status
    }

    public invokeAction(type: string) {
        this.params.context.componentParent.invokeGridAction({rowData: this.params.node.data, type});
    }

    refresh(): boolean {
        return false;
    }
}