import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiUtilService } from '../service/api-util.service';
import { DefaultTextCellRendererComponent } from '../data-grid/default-text-cell-renderer.component';
import { RuleTemplateListActionsComponent } from '../data-grid/rule-template-list-actions.component';
import { CommonService } from '../service/common.service';
import { apiProperties } from '../utility/constants';
import { DateTimeRendererComponent } from '../data-grid/date-time-renderer.component';
import { RuleTemplateStatusBadgeRenderer } from '../data-grid/rule-template-status-badge-renderer.component'
import { Utils } from '../utility/util';
declare var $: any;

@Component({
  selector: 'app-rule-templates-list',
  templateUrl: './rule-templates-list.component.html',
  styleUrls: ['./rule-templates-list.component.css']
})
export class RuleTemplatesListComponent implements OnInit {

  public searchText: string = '';
  public columnDefs: any[];
  public recTemplateList: any= [];
  public filteredRecTemplateList :any = [];
  public filterType :string;
  public activeRec :any =[];
  public inactiveRec :any = [];
  public isSubmodule : boolean;
  public pageSize: any = 10;
  public frameworkComponents: object;
  private gridApi: any;
  public context: any;
  public recStatusCount: any = [{count:0,label:"Total Recommendations"},{count:0,label:"Active Recommendations"},{count:0,label:"Inactive Recommendations"}];
  public recList:any = [{title:"Upsell (Support Plans)", isActive:true, updatedAt:"05/12/2024"},{title:"Eligibility", isActive:false, updatedAt:"05/10/2024"},{title:"Bundle Offering", isActive:true, updatedAt:"05/03/2024"},]
  public selectedRowObject:any = {};
  public invokeStatus : string;
  public ruleEventsList: any;
  public availableRuleEvents :any;
  decisionLogicValidation: boolean;


  constructor(
    private apiUtilService: ApiUtilService,
    private toastr: ToastrService,
    private router: Router,
    private cs: CommonService
  ) { }

  ngOnDestroy(){
    $('#delete-confirmation-modal').modal('hide');
    $('#clone-rule-template-modal').modal('hide');
    $('#status-confirmation-modal').modal('hide');
  }

  ngOnInit() {
    this.isSubmodule = this.apiUtilService.routedSubmoduleName != "";
    this.getAllRuleTemplates();
    this.initDataGridDetails();
  }

  initDataGridDetails(){
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: 'Name', field: 'name', sortable: true, suppressMovable: true, cellClass: 'text-custom-link',cellRenderer:'defaultTextCellRendererComponent' },
      { headerName: 'Status', field: 'status', sortable: true, suppressMovable: true, cellClass: 'p-0',cellRenderer:'ruleTemplateStatusBadgeRenderer'  },
      { headerName: 'Created', field: 'createdAt', sortable: true, suppressMovable: true, width: 120, cellClass: 'ag-text-val-color',cellRenderer:'dateTimeRendererComponent'  },
      { headerName: 'Updated', field: 'updatedAt', sortable: true, suppressMovable: true, width: 120, cellClass: 'ag-text-val-color',cellRenderer:'dateTimeRendererComponent'  },
      { headerName: 'Actions', sortable: false, suppressMovable: true, width: 115, cellClass: 'p-0',cellRendererParams: (params) => {
        return {
            status: params.data.status,
            isSubmodule: this.isSubmodule
        };
    } ,cellRenderer:'ruleTemplateListActionsComponent'}
    ];
    this.frameworkComponents = {
      defaultTextCellRendererComponent: DefaultTextCellRendererComponent,
      ruleTemplateListActionsComponent: RuleTemplateListActionsComponent, 
      dateTimeRendererComponent: DateTimeRendererComponent,
      ruleTemplateStatusBadgeRenderer: RuleTemplateStatusBadgeRenderer,
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.cs.updateCurrentGridAPI(this.gridApi);
  }

  resizeGrid() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onFilterTextBoxChanged(searchText: any){
    this.gridApi.setQuickFilter(searchText);
  }

  recommendationFilter(type:string){
    this.filterType = type;
    if(type == "Total Recommendations") this.filteredRecTemplateList = this.recTemplateList
    else if(type == "Active Recommendations") this.filteredRecTemplateList = this.activeRec
    else if(type == "Inactive Recommendations") this.filteredRecTemplateList = this.inactiveRec
  }

  getAllRuleTemplates() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_RECOMMENDATION_RULE_TEMPLATE;
    this.activeRec = [];
    this.inactiveRec = [];
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.recTemplateList = res.body.items || [];
        this.filteredRecTemplateList = this.recTemplateList;
        this.recStatusCount = [{count:0,label:"Total Recommendations"},{count:0,label:"Active Recommendations"},{count:0,label:"Inactive Recommendations"}];
        for(let i of this.recTemplateList){
          if(i.status === 'Active' || i.status === 'Enabled'){
            i.status = 'Active'
            this.activeRec.push(i);
            this.recStatusCount[1].count++;
          }else{
            i.status = 'Inactive';
            this.inactiveRec.push(i);
            this.recStatusCount[2].count++;
          } 
          this.recStatusCount[0].count++;
        }
        this.recommendationFilter(this.filterType);
        Utils.loader('#page-loader', 'hide');
        this.getAllRuleEvents();
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.log(err);
      }
    );
  }

  cloneRuleTemplate(edit?){
    try{
      if((this.selectedRowObject.event || '').trim() == '') throw 'Event is mandatory';
      Utils.loader('#page-loader', 'show');
      let currentAPIProperty = apiProperties.CLONE_RECOMMENDATION_RULE_TEMPLATE;
      let reqBody = {
        "event":this.selectedRowObject.event, 
        "runUnconditionally":this.selectedRowObject.runUnconditionally
      }
      this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.selectedRowObject.name), currentAPIProperty.method, reqBody).subscribe(
        (res: any) => {
          $('#clone-rule-template-modal').modal('hide');
          Utils.loader('#page-loader', 'hide');
          if(edit) this.invokeGridAction({rowData: res.body, type: 'edit'});
          else this.getAllRuleTemplates();
          this.toastr.success("Rule Template Cloned Successfully.");
        },
        (err: any) => {
          Utils.loader('#page-loader', 'hide');
          console.log(err);
        }
      );
    }catch(error){
      this.toastr.error(error);
    }
  }

  deleteRuleTemplate(){
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.DELETE_RECOMMENDATION_RULE_TEMPLATE_BY_NAME;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.selectedRowObject.name), currentAPIProperty.method).subscribe(
      (res: any) => {
        this.toastr.success('Rule Template (' + this.selectedRowObject.name + ') Deleted Successfully');
        $('#delete-confirmation-modal').modal('hide');
        Utils.loader('#page-loader', 'hide');
        this.getAllRuleTemplates();
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Rule Template (' + this.selectedRowObject.name + ') Delete Failed');
        Utils.loader('#page-loader', 'hide');
        console.log(err);
      }
    );
  }

  changeRuleTemplateStatus(status: string){
    this.decisionLogicValidation = false
    this.validateGroup(this.selectedRowObject.condition['any'] || this.selectedRowObject.condition['all'] || [])
    if(!this.selectedRowObject.runUnconditionally && this.decisionLogicValidation){
      this.toastr.error('Create a condition to activate this recommendation');
      $('#status-confirmation-modal').modal('hide');
    }
    else if(this.selectedRowObject.action.api === ''){
      this.toastr.error('Select action api to activate this recommendation');
      $('#status-confirmation-modal').modal('hide');
    }
    else{
      Utils.loader('#page-loader', 'show');
      let currentAPIProperty = (status === 'activate') ? apiProperties.ACTIVATE_RECOMMENDATION_RULE_TEMPLATE_BY_NAME : apiProperties.DEACTIVATE_RECOMMENDATION_RULE_TEMPLATE_BY_NAME;
      this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.selectedRowObject.name), currentAPIProperty.method, this.selectedRowObject).subscribe(
        (res: any) => {
          this.toastr.success(`${(status === 'activate') ? 'Activated' : 'Deactivated'} Successfully`);
          $('#status-confirmation-modal').modal('hide');
          Utils.loader('#page-loader', 'hide');
          this.getAllRuleTemplates();
        },
        (err: any) => {
          this.toastr.error((err.error || {}).message || 'Update Failed');
          Utils.loader('#page-loader', 'hide');
          console.error(err);
        }
      );
    }
  }

  invokeGridAction(params: any) {
    let currentRowData: object = params.rowData;
    let type: string = params.type;
    if(type === 'edit'){
      if (this.isSubmodule) this.router.navigate(['landing', { outlets: { studio: `submodule/${this.apiUtilService.routedSubmoduleName}/rule-template-studio/${currentRowData['name']}` }}]);
      else this.router.navigate(['landing', { outlets: { studio: `rule-template-studio/${currentRowData['name']}` }}]);
    } 
    else if (type === 'delete') {
      this.selectedRowObject = currentRowData;
      $('#delete-confirmation-modal').modal('show');
    }
    else if (type === 'activate') {
      this.selectedRowObject = currentRowData;
      this.invokeStatus = 'activate';
      $('#status-confirmation-modal').modal('show');
    } 
    else if (type === 'deactivate') {
      this.selectedRowObject = currentRowData;
      this.invokeStatus = 'deactivate';
      $('#status-confirmation-modal').modal('show');
    }  
    else if (type === 'clone') {
      this.selectedRowObject = JSON.parse(JSON.stringify(currentRowData));
      this.selectedRowObject.event = "";
     $('#clone-rule-template-modal').modal('show');
    }
  }


  getAllRuleEvents() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_RECOMMENDATION_RULE_EVENTS;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.ruleEventsList = res.body.items || [];
        Utils.loader('#page-loader', 'hide');
        this.getAvailableRuleEvents();
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.log(err);
      }
    );
  }

  getAvailableRuleEvents(){
    let bindedRuleEvents = [];
    let ruleEvents=[];
    for(let i of this.recTemplateList){
      bindedRuleEvents.push(i.event);
    }
    for(let i of this.ruleEventsList){
      ruleEvents.push(i.name);
    }
    this.availableRuleEvents = ruleEvents.filter(item => !bindedRuleEvents.includes(item));
  }

  validateDecision(obj:any){
    for(let key in obj ){
      if(key === 'all' || key === 'any'){
        this.validateGroup(obj[key])
      }
      else{
        if(obj[key] === '') return true;
      }
    }
    return false;
  }

  validateGroup(group:any){
    if(group.length == 0)
      this.decisionLogicValidation = true;
    for(let i=0; i < group.length; i++){
      if(this.validateDecision(group[i])){
        this.decisionLogicValidation = true;
      }
    }
    return group.length
  }


}


