import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiUtilService } from '../service/api-util.service';
import { CommonService } from '../service/common.service';
import { apiProperties } from '../utility/constants';
import { Utils } from '../utility/util';
declare var $: any;

@Component({
  selector: 'ccp-process-orchestration-landing',
  templateUrl: './process-orchestration-landing.component.html',
  styleUrls: ['./process-orchestration-landing.component.css']
})
export class ProcessOrchestrationLandingComponent implements OnInit {
  
  menuHeaderLabel: string;
  parentComponentDetails: any = {};
  devMode: string;
  isCloudDebugEnabled: boolean = false;
  devPort: string = '';
  isValidPort: boolean = false;
  activeProject: string = '';
  devModeTitle: string;
  gitCloneURL: string = 'git clone https://bitbucket.org/apptiumtechnologies/or-api-exec-engine-debugger.git';
  projectsList: any[];
  showProjectListDrpDown: boolean = false;
  addNewProj: any = {"type": "BLANK"};
  breadCrumbParentLabelMap: any = {
    "build": ["Objects", "APIs", "Analytics", "Process", "Event API", "Event Schema", "Rule Events", "Recommendations"],
    "processes": ["Process", "Event API", "Event Schema"],
    "cognitiveEngine": ["Rule Events", "Recommendations"],
    "manage": ["Integrations", "Custom Variables"],
    "monitor":[]
  };
  dataGridList: any = ['object-modeler-editor/new', 'object-modeler-editor/:id', 'internal-flow-list', 'object-list', 'process-list', 'event-api-list', 'event-schema-list', 'reports', 'custom-variables-list', 'analytics-list','rule-events-list','rule-templates-list'];
  currentNavPath: string;
  currentGridAPI: any;
  subModuleList: string[] = [];
  
  constructor(private toastr: ToastrService, public apiUtilService: ApiUtilService, private router: Router, private cs: CommonService) { 
    router.events.subscribe((navEnd: NavigationEnd) => {
      var routerEndSnapShot = (navEnd["snapshot"] || {}), currentRouteConfig = routerEndSnapShot.routeConfig, params = routerEndSnapShot.params;
      if(currentRouteConfig && (currentRouteConfig.outlet || "") == "studio") {
        this.currentNavPath = currentRouteConfig.path, 
        this.apiUtilService.routedSubmoduleName = params.moduleName || '',
        this.currentNavPath = this.currentNavPath == '' ? 'quick-access-orchestration-landing' : this.currentNavPath;
        switch (this.currentNavPath) {
          case 'quick-access-orchestration-landing':
            this.menuHeaderLabel = 'Build';
            break;
          case 'object-list':
          case 'object-modeler-editor/new':
          case 'object-modeler-editor/:id':
          case 'submodule/:moduleName/object-list':
          case 'submodule/:moduleName/object-modeler-editor/:id':
            this.menuHeaderLabel = 'Objects';
            break;
          case 'internal-flow-list':
          case 'internal-flow-studio/new':
          case 'internal-flow-studio/:id':
          case 'submodule/:moduleName/internal-flow-list':
          case 'submodule/:moduleName/internal-flow-studio/:id':
            this.menuHeaderLabel = 'APIs';
            break;
          case 'process-list':
          case 'process-studio/new':
          case 'process-studio/:name':
          case 'submodule/:moduleName/process-list':
          case 'submodule/:moduleName/process-studio/:name':
            this.menuHeaderLabel = 'Process';
            if($("#sidebar a[href='#processesSubmenu']").attr("aria-expanded") == 'false') $("#sidebar a[href='#processesSubmenu']").click();
            break;
          case 'event-api-list':
          case 'event-api-studio/new':
          case 'event-api-studio/:inputEvent':
          case 'submodule/:moduleName/event-api-list':
          case 'submodule/:moduleName/event-api-studio/:inputEvent':
            this.menuHeaderLabel = 'Event API';
            if($("#sidebar a[href='#processesSubmenu']").attr("aria-expanded") == 'false') $("#sidebar a[href='#processesSubmenu']").click();
            break;
          case 'event-schema-list':
          case 'event-schema-studio/new':
          case 'event-schema-studio/:name':
          case 'submodule/:moduleName/event-schema-list':
          case 'submodule/:moduleName/event-schema-studio/:name':
            this.menuHeaderLabel = 'Event Schema';
            if($("#sidebar a[href='#processesSubmenu']").attr("aria-expanded") == 'false') $("#sidebar a[href='#processesSubmenu']").click();
            break;
          case 'reports':
            this.menuHeaderLabel = 'Analytics';
            break;
          case 'analytics-list':
          case 'analytics-studio/new':
          case 'analytics-studio/:id':
          case 'submodule/:moduleName/analytics-list':
            this.menuHeaderLabel = 'Analytics';
            break;
          case 'intergrations-list':
          case 'intergrations-configuration/:id':
          case 'submodule/:moduleName/intergrations-list':
          case 'submodule/:moduleName/intergrations-configuration/:id':
            this.menuHeaderLabel = 'Integrations';
            if($("#sidebar a[href='#manageSubmenu']").attr("aria-expanded") == 'false') $("#sidebar a[href='#manageSubmenu']").click();
            break;
          case 'custom-variables-list':
          case 'submodule/:moduleName/custom-variables-list':
            this.menuHeaderLabel = 'Custom Variables';
            if($("#sidebar a[href='#manageSubmenu']").attr("aria-expanded") == 'false') $("#sidebar a[href='#manageSubmenu']").click();
            break;
          case 'user-list':
              this.menuHeaderLabel = 'Users';
              if($("#sidebar a[href='#manageSubmenu']").attr("aria-expanded") == 'false') $("#sidebar a[href='#manageSubmenu']").click();
              break;
          case 'monitor-logs':
          case 'monitor-logs/process/:processId':
          case 'monitorLogs':
          case 'monitorLogs/process/:processId':
            this.menuHeaderLabel = 'Process Monitor';
            break;
          case 'rule-events-list':
          case 'rule-events-studio/new':
          case 'rule-events-studio/:name':
          case 'submodule/:moduleName/rule-events-list':
          case 'submodule/:moduleName/rule-events-studio/:name':
            this.menuHeaderLabel = 'Rule Events';
            if($("#sidebar a[href='#cognitiveEngineSubmenu']").attr("aria-expanded") == 'false') $("#sidebar a[href='#cognitiveEngineSubmenu']").click();
            break;
          case 'rule-templates-list':
          case 'rule-template-studio/new':
          case 'rule-template-studio/:name':
          case 'submodule/:moduleName/rule-templates-list':
          case 'submodule/:moduleName/rule-events-studio/:name':
              this.menuHeaderLabel = 'Recommendations';
              if($("#sidebar a[href='#cognitiveEngineSubmenu']").attr("aria-expanded") == 'false') $("#sidebar a[href='#cognitiveEngineSubmenu']").click();
              break;
          default:
            break;
        }
      }
    });
  }

  ngOnInit() {
    $(document).on('show.bs.modal', '.modal', function () {
      var zIndex = 1040 + (10 * $('.modal:visible').length);
      $(this).css('z-index', zIndex);
      setTimeout(function () {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
      }, 0);
    });
    this.initDevConfigModalVars();
    this.getSubmoduleList();
    if(this.devMode === 'cloud'){
      this.devModeTitle = this.devMode;
      if(this.isCloudDebugEnabled) this.retrieveProjectsList();
    } else {
      let currentAPIProperty = apiProperties.GET_ACTIVE_PROJECT;
      Utils.loader('#page-loader', 'show');
      this.apiUtilService.invokeLocalAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
        (res: any) => {
          this.isValidPort = true;
          this.activeProject = res.body;
          this.devModeTitle = this.devMode + " (" + this.activeProject + ")";
          this.retrieveProjectsList();
        },
        (err: any) => {
          this.isValidPort = false;
          this.toastr.error("Invalid port number please check");
          Utils.loader('#page-loader', 'hide');
          $("#config-dev-mode-modal").modal("show");
        } 
      );
    }
    this.cs.menuBreadCrumbComponentDetails.subscribe((data) => {
        this.parentComponentDetails = data;
    });
    this.cs.currentGridAPIDetails.subscribe((data) => {
      this.currentGridAPI = data;
    });
  }

  toggleSideBar() {
    $('#sidebar').toggleClass('active');
    if($('#sidebar').hasClass('active')){
      $('.studio-wrapper').addClass('full-width');
    } else {
      $('.studio-wrapper').removeClass('full-width');
    }
    setTimeout(() => {
      if(this.dataGridList.indexOf(this.currentNavPath) != -1 && this.currentGridAPI) this.currentGridAPI.sizeColumnsToFit();
    }, 300);
  }

  menuNavigate(event: Event) {
    this.parentComponentDetails = {};
  }

  navigateSubmenu(event: Event, moduleName: string, metadataType: string) {
    this.menuNavigate(event);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => 
      this.router.navigate(['landing', { outlets: { studio: ['submodule', moduleName, metadataType] } }])
    );
  }

  navBack() {
    if(this.parentComponentDetails.label) {
      this.router.navigate(['landing', { outlets: { studio: this.parentComponentDetails.parentListComponentURL } }]);
      this.parentComponentDetails = {};
    }
  }

  openDevModeConfigModal() {
    this.initDevConfigModalVars();
    $("#config-dev-mode-modal").modal("show");
  }

  closeDevModeConfigModel() {
    if(this.devPort && !this.isValidPort){
      this.toastr.error('Invalid port number please check');
    }else{
      this.initDevConfigModalVars();
      $("#config-dev-mode-modal").modal("hide");
    }
  }

  validatePortNumber() {
    this.apiUtilService.localPortNumber = this.devPort;
    this.retrieveProjectsList();
  }

  initDevConfigModalVars() {
    this.apiUtilService.apiExecutionURL = null;
    this.devMode = localStorage.getItem("DEV-MODE");
    if(!this.devMode){
      localStorage.setItem("DEV-MODE", "cloud");
      this.devMode = "cloud";
    }
    this.devPort = this.apiUtilService.localPortNumber = localStorage.getItem("DEV-PORT") || '';
    this.isCloudDebugEnabled = localStorage.getItem("IS_CLOUD_DEBUG_ENABLED") == "true";
    if(this.devMode === "cloud") $(".rowHeader").addClass("headerBg");
  }

  saveDevModeConfiguration() {
    let currentAPIProperty = apiProperties.SET_ACTIVE_PROJECT;
    if(this.devMode == "local"){
      if(this.activeProject){
        Utils.loader('#page-loader', 'show');
        this.apiUtilService.invokeLocalAPI(currentAPIProperty.path, currentAPIProperty.method, { projectName: this.activeProject }).subscribe(
          (res: any) => {
            localStorage.setItem("DEV-MODE", this.devMode);
            localStorage.setItem("DEV-PORT", ((this.devPort || '') + '').trim());
            localStorage.removeItem("IS_CLOUD_DEBUG_ENABLED");
            this.devModeTitle = this.devMode + " (" + this.activeProject + ")";
            location.reload();
          },
          (err: any) => {
            this.toastr.error((err.error || {}).message || 'Save Failed');
            Utils.loader('#page-loader', 'hide');
          } 
        );
      }else{
        this.toastr.error('Please select project');
      }
    }else{
      if(this.isCloudDebugEnabled && !this.showProjectListDrpDown){
        this.toastr.error('Invalid port number please check');
      }else{
        Utils.loader('#page-loader', 'show');
        localStorage.setItem("DEV-MODE", this.devMode);
        localStorage.setItem("IS_CLOUD_DEBUG_ENABLED", this.isCloudDebugEnabled + "");
        localStorage.setItem("DEV-PORT", ((this.devPort || '') + '').trim());
        this.devModeTitle = this.devMode;
        location.reload();
      }
    }
  }

  updateDevMode(mode: string) {
    this.devMode = mode;
    if(this.devMode === "local" && (this.devPort || '').trim() !== "") this.retrieveProjectsList();
  }

  updateCloudDebug(isCloudDebugEnabled: boolean) {
    this.isCloudDebugEnabled = isCloudDebugEnabled;
    if(this.devPort) this.retrieveProjectsList();
  }

  copyGitURLCallback(params: any) {
    var iconTag = $(params.event);
    if($(params.event).is("button")) iconTag = $(params.event).find('i');
    iconTag.removeClass("fa-clone");
    iconTag.addClass("fa-check-circle");
    this.toastr.info('Copied to clipboard', '', { positionClass: 'toast-bottom-right' });
    setTimeout(() => {
      iconTag.removeClass("fa-check-circle");
      iconTag.addClass("fa-clone");
    }, 2000);
  }

  retrieveProjectsList() {
    if(this.devPort){
      this.projectsList = [];
      let currentAPIProperty = apiProperties.GET_ALL_PROJECTS_LIST;
      Utils.loader('#page-loader', 'show');
      this.apiUtilService.invokeLocalAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
        (res: any) => {
          this.isValidPort = true;
          this.projectsList = res.body;
          this.showProjectListDrpDown = true;
          Utils.loader('#page-loader', 'hide');
        },
        (err: any) => {
          this.isValidPort = false;
          this.showProjectListDrpDown = false;
          this.toastr.error((err.error || {}).message || err.error || 'Invalid port number please check.');
          Utils.loader('#page-loader', 'hide');
          $("#config-dev-mode-modal").modal("show");
        } 
      );
    }else{
      $("#config-dev-mode-modal").modal("show");
    }
  }

  getSubmoduleList() {
    this.subModuleList = [];
    let currentAPIProperty = apiProperties.GET_ACTIVE_PROJECT_SUBMODULE_NAMES;
    Utils.loader('#page-loader', 'show');
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.subModuleList = res.body || [];
        Utils.loader('#page-loader', 'hide');
      },
      (err: any) => {
        console.error(err);
        this.toastr.error("Error occurred while fetching project's submodule list");
        Utils.loader('#page-loader', 'hide');
      } 
    ); 
  }

  showAddNewProjectModal() {
    this.addNewProj = {"type": "BLANK"};
    $("#add-new-project-modal").modal("show");
  }

  validateAddNewProjectSave() {
    if (this.addNewProj.type === 'BLANK') return (this.addNewProj.projectName || '').trim() === '';
    else if (this.addNewProj.type === 'GIT') return (this.addNewProj.projectName || '').trim() === '' || (this.addNewProj.gitURL || '').trim() === '' || (this.addNewProj.userName || '').trim() === '' || (this.addNewProj.password || '').trim() === '';
  }

  createNewProject() {
    let currentAPIProperty = apiProperties.CREATE_NEW_PROJECT;
    Utils.loader('#page-loader', 'show');
    this.apiUtilService.invokeLocalAPI(currentAPIProperty.path, currentAPIProperty.method, this.addNewProj).subscribe(
      (res: any) => {
        $("#add-new-project-modal").modal("hide");
        this.toastr.success("(" + this.addNewProj.projectName +  ") project added to the workspace.", "Success");
        this.retrieveProjectsList();
      },
      (err: any) => {
        if(typeof(err.error) === "string"){
          this.toastr.error((err.error || {}) || 'Create Project Failed');
        } else {
          this.toastr.error((err.error || {}).message || 'Create Project Failed');
        }
        
        Utils.loader('#page-loader', 'hide');
      } 
    );
  }

  logout() {
    let currentAPIProperty = apiProperties.STUDIO_LOGOUT;
    this.apiUtilService.invokeStudioAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.router.navigate(['/login']);
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Logout Failed!');
        console.error(err);
      }
    );
  }

  onPaste(event: ClipboardEvent) {
    var clipboardData = event.clipboardData || window['clipboardData'];
    var pastedText = clipboardData.getData('text');
    if (pastedText.startsWith('git clone https://')) {
      this.addNewProj.gitURL = pastedText.substring(10);
      if(pastedText.indexOf('@bitbucket.org') != -1){
        this.addNewProj.userName = pastedText.substring(18).split("@")[0];
        this.addNewProj.gitURL = this.addNewProj.gitURL.replace(this.addNewProj.userName + '@', '');
      }
      event.preventDefault();
    }
  }

}
