import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUtilService } from './../service/api-util.service';
import { CommonService } from '../service/common.service';
import { apiProperties } from '../utility/constants';
import { ToastrService } from 'ngx-toastr';
import { Utils } from './../utility/util';
import { ObjectModelerService } from '../service/object-modeler.service';
import { ScriptEditorService } from '../service/script-editor.service';
import { ApiConnectionConfigurationComponent } from '../api-connection-configuration/api-connection-configuration.component';
declare var $: any;

@Component({
  selector: 'ccp-api-connector-configuration',
  templateUrl: './api-connector-configuration.component.html',
  styleUrls: ['./api-connector-configuration.component.css']
})
export class ApiConnectorConfigurationComponent implements OnInit {

  @ViewChild('textarea_scriptEditor', { static: true }) editor: any;
  @ViewChild(ApiConnectionConfigurationComponent, {static: false}) apiConnection: ApiConnectionConfigurationComponent;

  scriptEditor: any;
  isCreateFlow: boolean = false;
  appObjects: any;
  connectorId: string;
  connectorMetadata: any = {};
  connectorOperationList: any[] = [];
  connectorConfig: any = {};
  currentOperation: any;
  type: string = null;
  staticData: any = {
    advanceType: [
      { value: "", name: "--Select--" },
      { value: "date", name: "Date" },
      { value: "random", name: "Random" },
      { value: "reference", name: "Reference" }
    ],
    random_type: [
      {value: "", name: "--Select--"},
      {value: "numeric", name: "Numeric"},
      {value: "alphanumeric", name: "Alpha numeric"}
    ],
    date_format: [
      { value: "", name: "--Select--" },
      { value: "MM/DD/YYYY", name: "MM/DD/YYYY" },
      { value: "DD/MM/YYYY", name: "DD/MM/YYYY" },
      { value: "YYYY-MM-DD", name: "YYYY-MM-DD" },
      { value: "YYYY-MM-DDTHH:mm:ss.SSSZ", name: "YYYY-MM-DDTHH:mm:ss.SSSZ" }
    ],
    date_timezone: [
      { "value": "", "name": "--Select--" },
      { "value": "GMT-12:00", "name": "(GMT-12:00) International Date Line West" },
      { "value": "GMT-11:00", "name": "(GMT-11:00) Midway Island, Samoa" },
      { "value": "GMT-10:00", "name": "(GMT-10:00) Hawaii" },
      { "value": "GMT-09:00", "name": "(GMT-09:00) Alaska" },
      { "value": "GMT-08:00", "name": "(GMT-08:00) Pacific Time (US & Canada)" },
      { "value": "GMT-08:00", "name": "(GMT-08:00) Tijuana, Baja California" },
      { "value": "GMT-07:00", "name": "(GMT-07:00) Arizona" },
      { "value": "GMT-07:00", "name": "(GMT-07:00) Chihuahua, La Paz, Mazatlan" },
      { "value": "GMT-07:00", "name": "(GMT-07:00) Mountain Time (US & Canada)" },
      { "value": "GMT-06:00", "name": "(GMT-06:00) Central America" },
      { "value": "GMT-06:00", "name": "(GMT-06:00) Central Time (US & Canada)" },
      { "value": "GMT-06:00", "name": "(GMT-06:00) Guadalajara, Mexico City, Monterrey" },
      { "value": "GMT-06:00", "name": "(GMT-06:00) Saskatchewan" },
      { "value": "GMT-05:00", "name": "(GMT-05:00) Bogota, Lima, Quito, Rio Branco" },
      { "value": "GMT-05:00", "name": "(GMT-05:00) Eastern Time (US & Canada)" },
      { "value": "GMT-05:00", "name": "(GMT-05:00) Indiana (East)" },
      { "value": "GMT-04:00", "name": "(GMT-04:00) Atlantic Time (Canada)" },
      { "value": "GMT-04:00", "name": "(GMT-04:00) Caracas, La Paz" },
      { "value": "GMT-04:00", "name": "(GMT-04:00) Manaus" },
      { "value": "GMT-04:00", "name": "(GMT-04:00) Santiago" },
      { "value": "GMT-03:30", "name": "(GMT-03:30) Newfoundland" },
      { "value": "GMT-03:00", "name": "(GMT-03:00) Brasilia" },
      { "value": "GMT-03:00", "name": "(GMT-03:00) Buenos Aires, Georgetown" },
      { "value": "GMT-03:00", "name": "(GMT-03:00) Greenland" },
      { "value": "GMT-03:00", "name": "(GMT-03:00) Montevideo" },
      { "value": "GMT-02:00", "name": "(GMT-02:00) Mid-Atlantic" },
      { "value": "GMT-01:00", "name": "(GMT-01:00) Cape Verde Is." },
      { "value": "GMT-01:00", "name": "(GMT-01:00) Azores" },
      { "value": "GMT+00:00", "name": "(GMT+00:00) Casablanca, Monrovia, Reykjavik" },
      { "value": "GMT+00:00", "name": "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London" },
      { "value": "GMT+01:00", "name": "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
      { "value": "GMT+01:00", "name": "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
      { "value": "GMT+01:00", "name": "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris" },
      { "value": "GMT+01:00", "name": "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
      { "value": "GMT+01:00", "name": "(GMT+01:00) West Central Africa" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Amman" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Athens, Bucharest, Istanbul" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Beirut" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Cairo" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Harare, Pretoria" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Jerusalem" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Minsk" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Windhoek" },
      { "value": "GMT+03:00", "name": "(GMT+03:00) Kuwait, Riyadh, Baghdad" },
      { "value": "GMT+03:00", "name": "(GMT+03:00) Moscow, St. Petersburg, Volgograd" },
      { "value": "GMT+03:00", "name": "(GMT+03:00) Nairobi" },
      { "value": "GMT+03:00", "name": "(GMT+03:00) Tbilisi" },
      { "value": "GMT+03:30", "name": "(GMT+03:30) Tehran" },
      { "value": "GMT+04:00", "name": "(GMT+04:00) Abu Dhabi, Muscat" },
      { "value": "GMT+04:00", "name": "(GMT+04:00) Baku" },
      { "value": "GMT+04:00", "name": "(GMT+04:00) Yerevan" },
      { "value": "GMT+04:30", "name": "(GMT+04:30) Kabul" },
      { "value": "GMT+05:00", "name": "(GMT+05:00) Yekaterinburg" },
      { "value": "GMT+05:00", "name": "(GMT+05:00) Islamabad, Karachi, Tashkent" },
      { "value": "GMT+05:30", "name": "(GMT+05:30) Sri Jayawardenapura" },
      { "value": "GMT+05:30", "name": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
      { "value": "GMT+05:45", "name": "(GMT+05:45) Kathmandu" },
      { "value": "GMT+06:00", "name": "(GMT+06:00) Almaty, Novosibirsk" },
      { "value": "GMT+06:00", "name": "(GMT+06:00) Astana, Dhaka" },
      { "value": "GMT+06:30", "name": "(GMT+06:30) Yangon (Rangoon)" },
      { "value": "GMT+07:00", "name": "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
      { "value": "GMT+07:00", "name": "(GMT+07:00) Krasnoyarsk" },
      { "value": "GMT+08:00", "name": "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
      { "value": "GMT+08:00", "name": "(GMT+08:00) Kuala Lumpur, Singapore" },
      { "value": "GMT+08:00", "name": "(GMT+08:00) Irkutsk, Ulaan Bataar" },
      { "value": "GMT+08:00", "name": "(GMT+08:00) Perth" },
      { "value": "GMT+08:00", "name": "(GMT+08:00) Taipei" },
      { "value": "GMT+09:00", "name": "(GMT+09:00) Osaka, Sapporo, Tokyo" },
      { "value": "GMT+09:00", "name": "(GMT+09:00) Seoul" },
      { "value": "GMT+09:00", "name": "(GMT+09:00) Yakutsk" },
      { "value": "GMT+09:30", "name": "(GMT+09:30) Adelaide" },
      { "value": "GMT+09:30", "name": "(GMT+09:30) Darwin" },
      { "value": "GMT+10:00", "name": "(GMT+10:00) Brisbane" },
      { "value": "GMT+10:00", "name": "(GMT+10:00) Canberra, Melbourne, Sydney" },
      { "value": "GMT+10:00", "name": "(GMT+10:00) Hobart" },
      { "value": "GMT+10:00", "name": "(GMT+10:00) Guam, Port Moresby" },
      { "value": "GMT+10:00", "name": "(GMT+10:00) Vladivostok" },
      { "value": "GMT+11:00", "name": "(GMT+11:00) Magadan, Solomon Is., New Caledonia" },
      { "value": "GMT+12:00", "name": "(GMT+12:00) Auckland, Wellington" },
      { "value": "GMT+12:00", "name": "(GMT+12:00) Fiji, Kamchatka, Marshall Is." },
      { "value": "GMT+13:00", "name": "(GMT+13:00) Nuku'alofa" }
    ]
  };
  auth: any = {
    REST: [
      {value: "BASICAUTH", text: "BASICAUTH"},
      {value: "OAUTH1.0", text: "OAUTH1.0"},
      {value: "OAUTH2.0", text: "OAUTH2.0"},
      {value: "AWSSIGNATURE", text: "AWSSIGNATURE"}
    ],
    SOAP: [
      {value: "BASICAUTH", text: "BASICAUTH"},
      {value: "BEARERSECURITY", text: "BEARERSECURITY"},
      {value: "WSSECURITY", text: "WSSECURITY"},
      {value: "CUSTOMAUTH", text: "CUSTOMAUTH"}
    ]
  };
  connectorTypesToSkipOpMap: string[] = ["GCS", "FTP", "Database", "AWS CLI", "Power Shell"];
  file: File;
  private routedSubmoduleName: string;
  public isSubmodule: boolean;

  constructor(
    private route: ActivatedRoute,
    private apiUtilService: ApiUtilService,
    private scriptEditorService: ScriptEditorService,
    private oms: ObjectModelerService,
    private cs: CommonService,
    private toastr: ToastrService
  ) { 
    this.route.paramMap.subscribe(paramObject => {
      this.routedSubmoduleName = (paramObject['params']['moduleName']) || '';
      this.isSubmodule = this.routedSubmoduleName != '';
      this.connectorId = (paramObject['params']['id']) || '';
    });
  }

  ngOnInit() {
    document.title = "CCS - Integrations";
    this.scriptEditor = this.scriptEditorService.scriptEditor;
    this.getApplicationObjects();
    if(this.connectorId) {
      Utils.loader('#page-loader', 'show');
      this.isCreateFlow = false;
      let currentAPIProperty = apiProperties.GET_API_CONNECTOR_BY_ID;
      this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", this.connectorId), currentAPIProperty.method).subscribe(
        (res: any) => {
          Utils.loader('#page-loader', 'hide');
          let response: any = res.body || {};
          if (this.routedSubmoduleName) this.cs.updateMenuBreadCrumbDetails({ label: response.name, parentListComponentURL: `submodule/${this.routedSubmoduleName}/intergrations-list` });
          else this.cs.updateMenuBreadCrumbDetails({ label: response.name, parentListComponentURL: 'intergrations-list'});
          this.connectorMetadata = response;
          this.connectorOperationList = this.sortArray(response.conversionMap ? response.conversionMap.operation : []);
          this.connectorConfig = response.config;
          this.connectorConfig.auth = this.connectorConfig.auth || { auth_type: '', auth_details: { signature_method: '', grant_type: '' } };
          this.apiConnection.setConnectorConfig(this.connectorConfig);
        },
        (err: any) => {
          Utils.loader('#page-loader', 'hide');
          if(err.name == "HttpErrorResponse"){
            this.toastr.error("Something went wrong!");
          }
          console.error(err);
        }
      );
    }else{
      this.isCreateFlow = true;
    }
  }

  getApplicationObjects() {
      this.oms.getAllObjects((res: any) => {
        Utils.loader('#page-loader', 'hide');
        this.appObjects= res.body;
      }, (err: any) => {
        console.error(err);
        Utils.loader('#page-loader', 'hide');
      });
  }

  chooseFile(type: any) {
    if(type === "ftp"){
      document.getElementById("ftpFileName").click();
    } else if(type === "gcs"){
      document.getElementById("gcsFileName").click();
    }
  }

  changeFileEvent(event: any, type: any){
    let fileName = event.target.files[0].name;
    this.file = event.target.files[0];
    if(type === "ftp"){
      this.connectorConfig.ftpPpkFileName = fileName;
    } else if(type === "gcs"){
      this.connectorConfig.keyFileName = fileName;
    }
  }

  sortArray(inputArray: any[]){
    var sortedArray = (inputArray || []).sort((a, b) => {
      var textA = (a.name || "").toUpperCase();
      var textB = (b.name || "").toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    return sortedArray;
  }

  addItem(meta: any, itemKey: string){
    meta[itemKey] = meta[itemKey] || [];
    meta[itemKey].push({isAdvance: false});
  }

  deleteItem(itemList: any[], index: number){
    itemList.splice(index, 1);
  }

  createOperation() {
    let conversionMap = this.connectorMetadata.conversionMap;
    if(conversionMap){
      let operation = conversionMap.operation || [];
      let operationLength = operation.length+1;
      let operationId = Utils.getRandomId();
      let obj = {api: "", input: "", method: "", name: "operation_"+operationLength, output: "", operationId, request: {mappedObject: ""}, response: {mappedObject: ""}};
      this.connectorMetadata.conversionMap.operation.push(obj);
      setTimeout(() => {
        $("#operation-map-accordion .card:last-child")[0].scrollIntoView();
        $("#operation-map-accordion .card:last-child .card-header button").click();
      }, 0);
    }
  }

  updateConnectorData() {
    this.connectorConfig = this.apiConnection.getConfiguration();
    let connectorMetadata = this.connectorMetadata;
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      let str = fileReader.result;
      let base64 = btoa(str.toString());
      if(connectorMetadata.type === "FTP"){
        connectorMetadata.config.ftpPpkFileBase64Content = base64;
      } else if(connectorMetadata.type === "GCS"){
        connectorMetadata.config.keyFileBase64Content = base64;
      }
      this.updateConnector(connectorMetadata);
    }
    if(this.file){
      fileReader.readAsText(this.file);
    } else {
      this.updateConnector(connectorMetadata);
    }
  }

  updateConnector(connectorMetadata){
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.UPDATE_API_CONNECTOR_BY_ID;
    let reqBody = connectorMetadata;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", connectorMetadata.id), currentAPIProperty.method, reqBody).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        this.toastr.success("Updated Successfully!");
       },
       (err: any) => {
        Utils.loader('#page-loader', 'hide');
        this.toastr.error((err.error || {}).message || "Update Failed!");
        console.error(err);
      }
    );
  }

  testConnection(connectorConfig: any) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.TEST_CONNECTION_BY_CONNECTOR_DETAILS;
    let reqBody = connectorConfig;
    this.apiUtilService.invokeCloudAPI(currentAPIProperty.path, currentAPIProperty.method, reqBody).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        this.toastr.success("Connection Success!");
       },
       (err: any) => {
        Utils.loader('#page-loader', 'hide');
        // if(err.error && err.error.message){
        //   this.toastr.error(err.error.message);
        // } else {
          this.toastr.error("Connection Failed!");
        // }
        console.error(err);
      }
    );
  }

  openBusinessLogic(type: any, currentOperation: any) {
    this.type = type;
    this.currentOperation = currentOperation;
    let logic = currentOperation.logic = currentOperation.logic || {};

    if(!logic[type]) logic[type] = {scriptType: (type == "pre_rest_request" ? "ConnectorScript" : "ConnectorPostScript") , script: ""};
    
    $("#scriptEditorModal").modal("show");
    this.scriptEditorService.initReadOnlyLines(this.editor);
    this.scriptEditorService.setScriptValue("ConnectorScript_" + type, this.currentOperation.logic[type]['script'], false, this.editor);
  }

  saveScript() {
    this.currentOperation.logic[this.type].script = this.scriptEditorService.getScriptValue(null, this.editor);
    $("#scriptEditorModal").modal("hide");
  }

  addRetry(idx: any) {
    let retry = this.connectorOperationList[idx].retry;
    let retryCondition = {condition: "", isGrouped: false, key: "", value: ""};
    let retryData = {maxRetry: "", name: "", retryInterval: "", retryCondition: [retryCondition]};
    if(!retry){
      this.connectorOperationList[idx].retry = [];
      this.connectorOperationList[idx].retry.push(retryData);
    } else {
      this.connectorOperationList[idx].retry.push(retryData);
    }
  }

  deleteOperation(idx: any) {
    this.connectorOperationList.splice(idx, 1);
  }

  addProcessDecisionLogic(conIdx: any, idx: any) {
    let retryCondition = {condition: "", isGrouped: false, key: "", value: "", operator: ""};
    let retry = this.connectorOperationList[conIdx].retry[idx].retryCondition;
    if(retry && retry.length > 0){
      this.connectorOperationList[conIdx].retry[idx].retryCondition.push(retryCondition);
    }
  }

  deleteProcessDecisionLogic(par: any, subpar: any, idx: any) {
    this.connectorOperationList[par].retry[subpar].retryCondition.splice(idx, 1);
  }
  
  deleteRetry(paridx: any, idx: any) {
    this.connectorOperationList[paridx].retry.splice(idx, 1);
  }

  importSwaggerClick() {
    $("#importSwagger").click();
  }

  importSwagger(event: any) {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      let swaggerText = fileReader.result.toString();
      if(swaggerText && swaggerText !== ""){
        let swaggerJSON = JSON.parse(swaggerText);
        if(swaggerJSON.paths){
          Utils.loader('#page-loader', 'show');
          let swaggerData = {definition: swaggerJSON};
          let currentAPIProperty = apiProperties.IMPORT_SWAGGER;
          this.apiUtilService.invokeCloudAPI(currentAPIProperty.path, currentAPIProperty.method, swaggerData).subscribe(
            (res: any) => {
              Utils.loader('#page-loader', 'hide');
              let response: any = res.body || {};
              this.connectorMetadata.config = response.configuration;
              this.connectorMetadata.conversionMap.operation = this.connectorMetadata.conversionMap.operation.length>0 ? this.connectorMetadata.conversionMap.operation.concat(response.conversion_map.operation) : response.conversion_map.operation;
              this.connectorOperationList = this.connectorOperationList ? this.connectorOperationList.concat(response.conversion_map.operation) : response.conversion_map.operation;
              this.connectorConfig = response.configuration;
              this.connectorConfig.auth = this.connectorConfig.auth;
              $("#importSwagger").val("");
            },
            (err: any) => {
              $("#importSwagger").val("");
              Utils.loader('#page-loader', 'hide');
              this.toastr.error((err.error || {}).message || 'Import Failed');
              console.error(err);
            }
          );
        } else {
          $("#importSwagger").val("");
          this.toastr.error("Paths dosen't exists in the uploaded swagger file");
          return;
        }
      }
    };
    fileReader.readAsText(event.target.files[0]);
  }

  importIconClick() {
    $("#importIcon").click();
  }

  importIcon(event: any) {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      let imgStr = fileReader.result;
      this.connectorMetadata.iconBase64 = imgStr;
      $("#importIcon").val('');
    };
    fileReader.readAsDataURL(event.target.files[0]);
  }

  initOperationMapAccordion() {
    if ($("#operation-map-accordion .card-header").length > 0) {
      if($("#operation-map-accordion .card-header button[aria-expanded='true']").length > 0) {
        setTimeout(() => {
          $("#operation-map-accordion .card-header button[aria-expanded='true']")[0].scrollIntoView();
        }, 200);
      }else{
        $("#operation-map-accordion #op-header-0 button").click();
      }
    }
  }

}
