import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FlatTreeControl} from '@angular/cdk/tree';
import { Utils } from './../utility/util';
import { ToastrService } from 'ngx-toastr';
import { ApiUtilService } from './../service/api-util.service';
import { apiProperties } from '../utility/constants';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';


interface TreeNode {
  name: string;
  index:string;
  path:string;
  children?: TreeNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

interface RuleEventsMetadata {
  name: string;
  category: string;
  parameters: {
    conditions: any[]; 
    actions: any[];
  };
  input: string;
  output: string;
}

@Component({
  selector: 'app-rule-template-studio-objects',
  templateUrl: './rule-template-studio-objects.component.html',
  styleUrls: ['./rule-template-studio-objects.component.css']
})


export class RuleTemplateStudioObjectsComponent implements  OnInit {

  @Input() ruleTemplateMetadata: any;
  @Output() dataEmitter: EventEmitter<object> = new EventEmitter();

  private _transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      index: node.index,
      path: node.path,
      level: level
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
 
  decisionLogic: any={}
  temp1:any
  temp: any;
  objectsList:any={};
  objectsListMap:any={}
  treeData: any=[];
  eventName:string;
  validationStatus: boolean = false;
  validationError:string='';
  OperatorData:any={
    'All':{
      'is equal to': 'equal',
      'is not equal to': 'notEqual',
      'is greater than': 'greaterThan',
      'is greater than or equal to': 'greaterThanInclusive',
      'is less than': 'lessThan',
      'is less than or equal to': 'lessThanInclusive',
      'contains': 'contains',
      'does not contains': 'doesNotContain',
      'contains any': 'containsAny',
      'is in': 'in',
      'is not in': 'notin'
    },
    'Integer': {
      'is equal to': 'equal',
      'is not equal to': 'notEqual',
      'is greater than': 'greaterThan',
      'is greater than or equal to': 'greaterThanInclusive',
      'is less than': 'lessThan',
      'is less than or equal to': 'lessThanInclusive'
    },
    'String': {
      'is equal to': 'equal',
      'is not equal to': 'notEqual',
    },
    "Array":{
      'contains': 'contains',
      'does not contains': 'doesNotContain',
      'is in list': 'in',
      'is not in list': 'notin'
    }
  }
  treeMapData:any;
  operatorList:any={};
  operatorMap:any={};
  objectRelationMap:any={};
  ruleEventData: RuleEventsMetadata={
    name: "",
    category: "",
    parameters: {
      conditions:[],
      actions:[]
    },
    input: "",
    output: ""
  } ;
  constructor(
    private toastr: ToastrService,
    private apiUtilService: ApiUtilService,
  ) { }

  ngOnInit() {
    this.eventName = this.ruleTemplateMetadata.event;
    this.getRuleEventByName();
    this.getObjectList();
    this.decisionLogic = this.ruleTemplateMetadata.condition;
    if(Object.keys(this.decisionLogic).length === 0){
      this.decisionLogic['all']=[];
      this.addDecision(this.decisionLogic)
    }
  }

  ngOnDestroy() {
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  dataEmitToParent(){ // Validating Condition fileds
    let [key, value] = Object.entries(this.decisionLogic)[0]
    this.validationStatus = false
    this.validateGroup(value)
    this.dataEmitter.emit(
      { "decisionLogic":this.decisionLogic,
        "validationStatus" : this.validationStatus,
        "validationError" : this.validationError,
      })
  }

  validateDecision(obj:any, parent:any , index:number){
    let empty =0;
    for(let key in obj ){
      if(key === 'all' || key === 'any'){
        let grouplength = this.validateGroup(obj[key])
        if(grouplength == 0) empty = 3
        else empty = -1; 
      }
      else{
        if(obj[key] === '') empty++;
      }
    }
    
    if(empty == 3){
      this.deleteDecision(obj,parent,index);
    }
    return empty;
    
  }

  validateGroup(group:any){
    for(let i=0; i < group.length; i++){
      let empty = this.validateDecision(group[i],group,i)
      if(empty == 3){
        i--;
      }
      if(empty == 2 || empty == 1){
        this.validationStatus = true;
        this.validationError = 'Fields Cannot be Empty';
      }
    }
    if( ( group == this.decisionLogic['all'] || group == this.decisionLogic['any'] )  && group.length == 0 ){
      if(!this.ruleTemplateMetadata.runUnconditionally){
        this.addDecision(this.decisionLogic);
        this.validationStatus = true;
        this.validationError = "Condition cannot be empty";
      }else{
        this.decisionLogic = {};
      }

    }
    return group.length
  }

  addDecision(item:any){
    let type;
    if(item.hasOwnProperty('select'))
      type = 'select'
    else if(item.hasOwnProperty('any'))
      type = 'any'
    else if(item.hasOwnProperty('all'))
      type = 'all'
    item[type].push({
      fact: '',
      operator: '',
      value : '',
    })
  }

  addGroup(itemObj:any){
    let type;
    if(itemObj.hasOwnProperty('select'))
      type = 'select'
    else if(itemObj.hasOwnProperty('any'))
      type = 'any'
    else if(itemObj.hasOwnProperty('all'))
      type = 'all'
    let item = itemObj[type]
    let newObj = {};
    newObj['all']=[];
    item.push(newObj);
    let ind=item.length
    item[ind-1]['all'].push({
      fact: '',
      operator: '',
      value : '',
    })
  }

  deleteDecision(obj:any,item:any,index:any){
    item.splice(index,1);
  }

  deleteGroup(obj:any,item:any,index:any){
    item.splice(index,1)
  }

  getObjectLength(obj:any){
    return Object.keys(obj).length;
  }

  updateConditionType(oldKey: string, newKey: string, itemDecision:any){
    if (newKey !== oldKey) {
      itemDecision[newKey] = itemDecision[oldKey];
      delete itemDecision[oldKey];
    }
  }

  hasArrayValue(obj: any): boolean {
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        return true;
      }
    }
    return false;
  }



  handleDragStart(event, node) {
    const spanElement = (event.target as HTMLElement).querySelector('.tree-child');
    if (spanElement) {
        const dragImage = spanElement.cloneNode(true) as HTMLElement;
        dragImage.style.position = 'absolute';
        dragImage.style.top = '-9999px'; 
        dragImage.style.backgroundColor = 'white';
        dragImage.style.minWidth = '150px';
        document.body.appendChild(dragImage);  
        event.dataTransfer!.setDragImage(dragImage, 0, 0);
        setTimeout(() => document.body.removeChild(dragImage), 0);
    }
    this.temp = node.path;
  }

  handleDrop(event, node) {
    event.preventDefault();
  }

  allowDrop(event: DragEvent): void {
    event.preventDefault();
  }

  handleInputDrop(event: DragEvent, attrDet:any): void {
    event.preventDefault();
    attrDet.fact = this.temp
    attrDet.operator = ''
    attrDet.value = ''
  }

  getRuleEventByName(){
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties. GET_RECOMMENDATION_RULE_EVENT_BY_NAME;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.eventName), currentAPIProperty.method).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        this.ruleEventData = res.body || {};
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        console.error(err);
        this.toastr.error("Rule Event does not exist.");
      }
    );
  }



  getAncestors(array, name) {
    if (typeof array != "undefined") {
      for (let i = 0; i < array.length; i++) {
        if (array[i].name === name) {
          return [array[i]];
        }
        const a = this.getAncestors(array[i].children, name);
        if (a !== null) {
          a.unshift(array[i]);
          return a;
        }
      }
    }
    return null;
  }

  emptyFact(attrDet:any){
    attrDet.fact = '';
  }

  getObjectList() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_OBJECT_METADATA;
    //currentAPIProperty.path += '?isCognitiveRuleModel=true';
    this.apiUtilService.invokeAPI(currentAPIProperty.path , currentAPIProperty.method).subscribe(
      (res: any) => {
        this.objectsList = res.body || [];
        this.convertObjectsListToMap();
        this.buildTreeData();
        Utils.loader('#page-loader', 'hide');
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.log(err);
      }
    );
  }
  
  buildTreeData() {
    this.objectsList.forEach((model,index) => this.treeData.push(this.convertObjectToTree(model,index.toString(),model.model)) );
    this.dataSource.data = this.treeData;
    this.treeMapData = this.dataSource.data;
  }

  convertObjectToTree(obj: any, parentIndex, parentPath) {
    var currentTreeObj: any = { 
      name: obj.model,
      index: parentIndex,
      path: parentPath
    };

    if ((obj.attributes || []).length > 0) {
      currentTreeObj.children = currentTreeObj.children || [];
      (obj.attributes || []).forEach((attr: any,attrIndex: number) => {
        if(attr.type){
          let type = attr.type;
          type = (type == 'Integer' || type == 'Long')? 'Integer' : 'String'
          this.operatorMap[parentPath+"."+attr.name] = this.OperatorData['All'];
          // this.operatorMap[parentPath+"."+attr.name] = this.OperatorData[type];
        }
        currentTreeObj.children.push({ 
          name: attr.name,
          index: parentIndex + '.' + attrIndex.toString(),
          path: parentPath+"."+attr.name
        })
      } );
    }
    if ((obj.relationships || []).length > 0) {
      currentTreeObj.children = currentTreeObj.children || [];
      obj.relationships.forEach(
        (rel: any,relIndex: number) => currentTreeObj.children.push(
          this.convertObjectToTree(
            this.getRelationshipModel(rel.model),
            (parentIndex + '.' + (relIndex+(obj.attributes || []).length).toString()),
            parentPath+'.'+rel.model
          )
        ) 
      );
    }
    return currentTreeObj;
  }

  getRelationshipModel(model: string) {
    if(this.objectsListMap.hasOwnProperty(model)){
      return this.objectsListMap[model]
    }
    return undefined;
  }

  convertObjectsListToMap(){
    for(let i of this.objectsList){
      this.objectsListMap[i.model]={ ...i };
    }
  }

  //filter tree
  filterRecursive(filterText: string, array: any[], property: string) {
    let filteredData;
    function copy(o: any) {
      return Object.assign({}, o);
    }
    if (filterText) {
      filterText = filterText.toLowerCase();
      filteredData = array.map(copy).filter(function x(y) {
        if (y[property].toLowerCase().includes(filterText)) {
          return true;
        }
        if (y.children) {
          return (y.children = y.children.map(copy).filter(x)).length;
        }
      });
    } else {
      filteredData = array;
    }

    return filteredData;
  }
  
  filterTree(filterText: string) {
    this.dataSource.data = this.filterRecursive(filterText, this.treeData, 'name');
  }

  // filter string from mat input filter
  applyFilter(filterText: string) {
    this.filterTree(filterText);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }

}
