import { Component, OnInit, ViewChild, ElementRef, HostListener  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Utils } from './../utility/util';
import { ApiUtilService } from './../service/api-util.service';
import { apiProperties } from '../utility/constants';
import { CommonService } from '../service/common.service';
import { InternalFlowService } from '../service/internal-flow.service';
import { EventApiService } from '../service/event-api.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {RuleTemplateStudioObjectsComponent } from '../rule-template-studio-objects/rule-template-studio-objects.component'
declare var $: any;

interface EventTree {
  name: string;
  children?: EventTree[];
}

@Component({
  selector: 'app-rule-template-studio',
  templateUrl: './rule-template-studio.component.html',
  styleUrls: ['./rule-template-studio.component.css']
})
export class RuleTemplateStudioComponent implements OnInit {

  @ViewChild(RuleTemplateStudioObjectsComponent, { static: false }) childComponent: RuleTemplateStudioObjectsComponent;

  treeControl = new NestedTreeControl<EventTree>(node => node.children);
  dataSource = new MatTreeNestedDataSource<EventTree>();

  public isCreateFlow: boolean = false;
  private routedSubmoduleName: string;
  public isSubmodule: boolean;
  public activeTab: string;
  public eventTree:any ={};
  public ruleTemplateName: string='';
  public popUpStatus:string;
  public isFinish:boolean = false;
  public bindedRuleEventsMap :any = {}
  public decisionLogicValidation:boolean;
  public dropDownList:boolean = false;
  public internalFlowApiList = [];
  public internalFlowApiListMap = {};
  public interceptorSourceAPIList = [];
  public selectedApi:string;
  public ruleTemplateMetadata : any ={
    "name": "",
    "event": "",
    "condition": {},
    "action": {
        "api": ""
    },
    "runUnconditionally":false,
    "status": "",
    "apiNames": [],
    "apiInterceptorType": ""
  }
  public decisionAttrs:any = [{
    "attr": "",
    "attrValue": "",
    "operation": "",
  }]
  temp:any;
  public recTemplateList: any;
  public ruleEventsList: any;
  public availableRuleEvents: string[];
  private apiSettings: any;
  public selectedNextActionId: string = "";
  public showNextActionCanvas: boolean = false;
  public apiInterceptorIndicator: boolean = false;
  selectedNode: EventTree = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private apiUtilService: ApiUtilService,
    private cs: CommonService,
    private internalFlowService: InternalFlowService,
    private eventApiService: EventApiService,
    private eRef: ElementRef
  ) {
    this.route.paramMap.subscribe(paramObject => {
      this.routedSubmoduleName = (paramObject['params']['moduleName']) || '';
      this.isSubmodule = this.routedSubmoduleName != '';
      this.ruleTemplateName = (paramObject['params']['name']) || '';
    });
    this.apiSettings = this.eventApiService.apiSettings;
    this.reset();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const dropdown = document.getElementById('customDropDown');
    if (dropdown && !dropdown.contains(target)) {
      this.dropDownList = false;
    }
  }

  hasChild = (_: number, node: EventTree) => !!node.children && node.children.length > 0;

  ngOnDestroy(){
    $('#status-confirmation-modal').modal('hide');
  }

  ngOnInit() {
    document.title = "CCS - Rule Templates";
    this.getAllRuleTemplates((err: any) => {
      if (err) {
        console.error(err);
        return;
      }
      if(this.ruleTemplateName){
        Utils.loader('#page-loader', 'show');
        this.isCreateFlow = false;
        let currentAPIProperty = apiProperties. GET_RECOMMENDATION_RULE_TEMPLATE_BY_NAME;
        this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.ruleTemplateName), currentAPIProperty.method).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            if (this.routedSubmoduleName) this.cs.updateMenuBreadCrumbDetails({ label: this.ruleTemplateName, parentListComponentURL: `submodule/${this.routedSubmoduleName}/rule-templates-list` });
            else this.cs.updateMenuBreadCrumbDetails({ label: this.ruleTemplateName, parentListComponentURL: 'rule-templates-list'});
            this.ruleTemplateMetadata = res.body || {};
            if (this.ruleTemplateMetadata.action.api) this.initNextActionAPISettings();
            if ((this.ruleTemplateMetadata.apiNames || []).length > 0) this.apiInterceptorIndicator = true;
            this.getAllInternalFlowMetadata();
            this.getAllInterceptorSourceAPIList();
          },
          (err: any) => {
            Utils.loader('#page-loader', 'hide');
            console.error(err);
            this.toastr.error("Rule Template does not exist.");
            this.router.navigate(['landing', { outlets: { studio: 'rule-templates-list' } }]);
          }
        );
      }else{
        this.isCreateFlow = true;
        this.getAllInterceptorSourceAPIList();
        this.cs.updateMenuBreadCrumbDetails({ label: 'Create', parentListComponentURL: 'rule-templates-list'});
      }
    });
  }

  reset() {
    this.activeTab = "BASIC-INFO";
  }

  nextStep(){
    try{
      switch(this.activeTab){
        case "BASIC-INFO":
            try{
              if(!this.ruleTemplateMetadata.event) throw "Event is required"
              else if(this.apiInterceptorIndicator && (this.ruleTemplateMetadata.apiNames || []).length == 0) throw "API Names are required"
              else this.activeTab = "OBJECTS";   
            } catch (error) {
              this.toastr.error(error);
            }
          break;
        case "OBJECTS":
          this.childComponent.dataEmitToParent();
          break;
        case "NEXT-ACTION":
          this.activeTab = "FINISH"
          setTimeout(() => {
            this.router.navigate(['landing', { outlets: { studio: 'rule-templates-list' } }]);
          }, 2000); 
          break;
      }
    }catch (error) {
      //this.toastr.error(error.message);
    }
  }

  previousStep(){
    try{
      switch(this.activeTab){
        case "OBJECTS":
          this.activeTab = "BASIC-INFO";
          break;
        case "NEXT-ACTION":
          this.activeTab = "OBJECTS";
          break;
        case "FINISH":
          break;
      }
    }catch (error) {
      //this.toastr.error(error.message);
    }
  }

  onClickTab(tabName){
    this.activeTab = tabName;
  }

  getAllRuleTemplates(callback: any) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_RECOMMENDATION_RULE_TEMPLATE;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.recTemplateList = res.body.items || [];
        this.getAllRuleEvents();
        this.getAllInternalFlowMetadata();
        Utils.loader('#page-loader', 'hide');
        callback();
        
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.log(err);
        callback(err);
      }
    );
  }

  getAllRuleEvents() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_RECOMMENDATION_RULE_EVENTS;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.ruleEventsList = res.body.items || [];
        Utils.loader('#page-loader', 'hide');
        this.getAvailableRuleEvents();
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.log(err);
      }
    );
  }

  getAvailableRuleEvents(){
    let bindedRuleEvents = [];
    let ruleEvents=[];
    for(let i of this.recTemplateList){
      if(i.name !== this.ruleTemplateName){ 
          bindedRuleEvents.push(i.event);
          this.bindedRuleEventsMap[i.event] = true
        }
    }
    for(let i of this.ruleEventsList){
      ruleEvents.push(i.name);
    }
    this.eventTree = this.ruleEventsList.reduce((acc, item) => {
      let category = acc.find(cat => cat.name === item.category);
      if (!category) {
        category = { name: item.category, children: [] };
        acc.push(category);
      }
      category.children.push({ name: item.name, ...item });
      return acc;
    }, []);
    this.dataSource.data = this.eventTree
    this.availableRuleEvents = ruleEvents.filter(item => !bindedRuleEvents.includes(item));
  }

  getAllInternalFlowMetadata() {
    this.internalFlowService.getAllInternalFlows(
      (res: any) => {
        for(let i of res.body){
          this.internalFlowApiListMap[i.id] = i.name;
        }
        if(this.ruleTemplateMetadata.action.api) this.selectedApi = this.internalFlowApiListMap[this.ruleTemplateMetadata.action.api] 
        this.internalFlowApiList=res.body;
      }, 
      (err: any) => { console.error(err); }
    );
  }

  getAllInterceptorSourceAPIList() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_RECOMMENDATION_RULE_TEMPLATE_SOURCEAPI_LIST;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.interceptorSourceAPIList = res.body || [];
        Utils.loader('#page-loader', 'hide');
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.log(err);
      }
    );
  }

  initNextActionAPISettings() {
    Utils.loader('#page-loader', 'show');
    this.showNextActionCanvas = false;
    this.eventApiService.isInternalFlow = true;
    if(this.ruleTemplateMetadata.action.api){
      setTimeout(() => {
        this.showNextActionCanvas = true;
        this.apiSettings.init({ isCallFrmProcessFlow: false, id: this.ruleTemplateMetadata.action.api, openRecAdvSettings: true });
      }, 0);
    }
    Utils.loader('#page-loader', 'hide');
   
  }

  receiveData(data:any) {
    this.ruleTemplateMetadata.condition = data.decisionLogic;
    this.decisionLogicValidation = data.validationStatus;
    if( !this.decisionLogicValidation && this.activeTab === "OBJECTS"){
      this.activeTab = "NEXT-ACTION";
    }else{
      this.toastr.error(data.validationError);
    }  
  }

  saveOrUpdateRuleTemplate() {
    try {
      if (this.isCreateFlow && this.ruleTemplateMetadata.event == '') throw ('Event is required');
      if (!this.apiInterceptorIndicator) {
        this.ruleTemplateMetadata.apiNames = [];
        this.ruleTemplateMetadata.apiInterceptorType = "";
      } else if ((this.ruleTemplateMetadata.apiNames || []).length == 0) throw "API Names are required";
      if (this.popUpStatus == 'finish' && this.ruleTemplateMetadata.action.api == '') throw ('Action API is required');
      if (this.popUpStatus == 'finish') this.ruleTemplateMetadata.status = "Active";
      if (this.isCreateFlow) this.saveRuleTemplate();
      else this.updateRuleTemplate(this.ruleTemplateMetadata.name);
    } catch (e) {
      this.toastr.error(e);
    }
  }

  saveRuleTemplate(){
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.SAVE_RECOMMENDATION_RULE_TEMPLATE;
    this.apiUtilService.invokeAPI(currentAPIProperty.path+'?isFinish', currentAPIProperty.method, this.ruleTemplateMetadata).subscribe(
      (res: any) => {
        this.toastr.success('Rule Template Saved Successfully');
        Utils.loader('#page-loader', 'hide');
        if(this.popUpStatus == 'finish'){
          this.activeTab = 'FINISH'
          setTimeout(() => {
            this.router.navigate(['landing', { outlets: { studio: 'rule-templates-list' } }]);
          }, 2000);
        }
        else this.router.navigate(['landing', { outlets: { studio: 'rule-templates-list' } }]);  
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Save Failed');
        Utils.loader('#page-loader', 'hide');
        console.error(err);
      }
    );
  }

  updateRuleTemplate(name: string) {
    //validation for status when clicked on save and exit..
    //if(!this.ruleTemplateMetadata.runUnconditionally && this.decisionLogicValidation && !this.isFinish) this.ruleTemplateMetadata.status = 'Inactive'
    if(!this.isFinish) this.ruleTemplateMetadata.status = 'Inactive';
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.UPDATE_RECOMMENDATION_RULE_TEMPLATE_BY_NAME;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", name)+'?isFinish', currentAPIProperty.method, this.ruleTemplateMetadata).subscribe(
      (res: any) => {
        this.toastr.success('Rule Template Updated Successfully');
        Utils.loader('#page-loader', 'hide');
        if(this.popUpStatus == 'finish'){
          this.activeTab = 'FINISH'
          setTimeout(() => {
            this.router.navigate(['landing', { outlets: { studio: 'rule-templates-list' } }]);
          }, 2000);
        }
        else this.router.navigate(['landing', { outlets: { studio: 'rule-templates-list' } }]);
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Update Failed');
        Utils.loader('#page-loader', 'hide');
        console.error(err);
      }
    );
  }

  emptyCondition(){
    this.ruleTemplateMetadata.condition = {}
  }

  updatePopUpStatus(status:string){
      $('#status-confirmation-modal').modal('show');
      this.popUpStatus = status;
  }

  statusConfirmation(){
    if(this.popUpStatus == 'save'){
      this.isFinish = false;
      this.decisionLogicValidation = false
      this.validateGroup(this.ruleTemplateMetadata.condition['any'] || this.ruleTemplateMetadata.condition['all'] || [])
      this.saveOrUpdateRuleTemplate()
    }
    else if(this.popUpStatus == 'cancel'){
      this.router.navigate(['landing', { outlets: { studio: ['rule-templates-list'] } }]);
    }
    else if(this.popUpStatus == 'finish'){
      this.isFinish = true;
      this.saveOrUpdateRuleTemplate();
    }
    $('#status-confirmation-modal').modal('hide');
  }


  selectEvent(event: EventTree): void {
    this.emptyCondition()
    if (this.ruleTemplateMetadata.event === event) {
      this.ruleTemplateMetadata.event = ''; // Deselect if already selected
    } else {
      this.ruleTemplateMetadata.event = event;
    }
  }

  isEventSelected(event: EventTree): boolean {
    return this.ruleTemplateMetadata.event === event;
  }

  showDropdownList(){
    this.dropDownList = !this.dropDownList;
    this.internalFlowApiList=this.internalFlowService.internalFlowList;
  }

  filterFunction(event: KeyboardEvent){
    const inputElement = event.target as HTMLInputElement;
    const filterValue = inputElement.value.toLowerCase();
    this.internalFlowApiList= (this.internalFlowService.internalFlowList).filter(item => item.name.toLowerCase().includes(filterValue));
  }

  selectApiFunction(id:string,name:string){
    this.ruleTemplateMetadata.action.api = id;
    this.selectedApi = name;
    this.dropDownList = !this.dropDownList;
    this.initNextActionAPISettings()
  }

  validateDecision(obj:any){
    for(let key in obj ){
      if(key === 'all' || key === 'any'){
        this.validateGroup(obj[key])
      }
      else{
        if(obj[key] === '') return true;
      }
    }
    return false;
  }

  validateGroup(group:any){
    if(group.length == 0)
      this.decisionLogicValidation = true;
    for(let i=0; i < group.length; i++){
      if(this.validateDecision(group[i])){
        this.decisionLogicValidation = true;
      }
    }
    return group.length
  }

}
