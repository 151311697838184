import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFormOptions } from "@ngx-formly/core";
import { CommonService } from '../service/common.service';
import { ProcessListService } from './../service/process-list.service';
import { ApiUtilService } from './../service/api-util.service';
import { apiProperties } from '../utility/constants';
import { ToastrService } from 'ngx-toastr';
import { Utils } from './../utility/util';
import * as beautify from 'js-beautify';

interface RuleEventsMetadata {
  name: string;
  category: string;
  executesAt: any[];
  parameters: {
    conditions: any[]; 
    actions: any[];
  };
  input: string;
  output: string;
}

@Component({
  selector: 'app-rule-events-studio',
  templateUrl: './rule-events-studio.component.html',
  styleUrls: ['./rule-events-studio.component.css']
})
export class RuleEventsStudioComponent implements OnInit {

  @ViewChild('textarea_ConditionEditor', { static: false }) ConditionEditor: any;
  @ViewChild('textarea_ActionEditor', { static: false }) ActionEditor: any;

  
  public isCreateFlow: boolean = false;
  private routedSubmoduleName: string;
  public isSubmodule: boolean;
  private ruleEventName: string;
  public conditionData: string = '[]';
  public actionData: string = '[]';
  public recommendationCategoryMap: any = {};
  public options: FormlyFormOptions = {};
  public showCodeMirrorPanel: boolean = true;
  public ruleEventsList: any;
  public ruleEventsMetadata: RuleEventsMetadata =  {
    name: "",
    category: "",
    executesAt: [],
    parameters: {
      conditions:[],
      actions:[]
    },
    input: "",
    output: ""
  } ;
 
  public ConditionEditorOptions: any = {
    theme: 'blackboard',
    mode: 'application/ld+json',
    lineNumbers: true,
    lineWrapping: true,
    tabSize: 4,
    foldGutter: true,
    scrollbarStyle: 'overlay',
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers', 'breakpoints'],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
  }
  public ActionEditorOptions: any = {
    theme: 'blackboard',
    mode: 'application/ld+json',
    lineNumbers: true,
    tabSize: 4,
    lineWrapping: true,
    foldGutter: true,
    scrollbarStyle: 'overlay',
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers', 'breakpoints'],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true
  }


  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiUtilService: ApiUtilService,
    private cs: CommonService,
    public processListService: ProcessListService,
    private toastr: ToastrService
  ) { 
    this.route.paramMap.subscribe(paramObject => {
      this.routedSubmoduleName = (paramObject['params']['moduleName']) || '';
      this.isSubmodule = this.routedSubmoduleName != '';
      this.ruleEventName = (paramObject['params']['name']) || '';
    });
  }

  ngOnInit() {
    document.title = "CCS - Rule Events";
    this.getAllRuleEvents((err: any) => {
      if (err) {
        console.error(err);
        return;
      }
      if(this.ruleEventName) {
        Utils.loader('#page-loader', 'show');
        this.isCreateFlow = false;
        let currentAPIProperty = apiProperties. GET_RECOMMENDATION_RULE_EVENT_BY_NAME;
        this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", this.ruleEventName), currentAPIProperty.method).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            if (this.routedSubmoduleName) this.cs.updateMenuBreadCrumbDetails({ label: this.ruleEventName, parentListComponentURL: `submodule/${this.routedSubmoduleName}/rule-events-list` });
            else this.cs.updateMenuBreadCrumbDetails({ label: this.ruleEventName, parentListComponentURL: 'rule-events-list'});
            this.getAllCategoryList();
            this.ruleEventsMetadata = res.body || {};
            this.conditionData = JSON.stringify(this.ruleEventsMetadata.parameters.conditions,null,2);
            this.actionData = JSON.stringify(this.ruleEventsMetadata.parameters.actions,null, 2);
            this.setConditionField(this.conditionData);
            this.setActionField(this.actionData);
          },
          (err: any) => {
            Utils.loader('#page-loader', 'hide');
            console.error(err);
            this.toastr.error("Rule Event does not exist.");
            this.router.navigate(['landing', { outlets: { studio: 'rule-events-list' } }]);
          }
        );
      }else{
        this.getAllCategoryList();
        this.isCreateFlow = true;
        this.cs.updateMenuBreadCrumbDetails({ label: 'Create', parentListComponentURL: 'rule-events-list'});
      }
    });
  }

  setConditionField(value: string) {
    try {
      this.conditionData = value;
      this.ruleEventsMetadata.parameters.conditions = eval(value);
    } catch (error) {
    
    }
  }
  setActionField(value: string) {
    try { 
      this.actionData = value;
      this.ruleEventsMetadata.parameters.actions = eval(value);  
    } catch (error) {
    
    }
  }

  getAllCategoryList() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_RECOMMENDATION_CATEGORY_LIST;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.recommendationCategoryMap = {};
        (res.body || []).forEach((c: any) => this.recommendationCategoryMap[c.name] = c );
        Utils.loader('#page-loader', 'hide');
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.log(err);
      }
    );
  }
  
  saveOrUpdateRuleEvents(ruleEventsMetadata:RuleEventsMetadata){
    if(this.isCreateFlow){
      try{
        if((ruleEventsMetadata.name || '').trim() == '') throw 'Name is mandatory';
        if((ruleEventsMetadata.category || '').trim() == '') throw 'Category is mandatory';
        this.ruleEventsMetadata.name =  this.ruleEventsMetadata.name.replace(/\s+$/, '');
        let isExist = this.ruleEventsList.find((ruleEvent: any) => ruleEvent.name === ruleEventsMetadata.name);
        if(isExist) throw 'Rule Event name already exists';
        this.saveRuleEvent(ruleEventsMetadata);
      } catch (error) {
        this.toastr.error(error);
      }
    }else{
      this.updateRuleEvent(this.ruleEventName,ruleEventsMetadata)
    }
     
  }

  saveRuleEvent(ruleEventsMetadata:RuleEventsMetadata) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.SAVE_RECOMMENDATION_RULE_EVENT;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, ruleEventsMetadata).subscribe(
      (res: any) => {
        this.toastr.success('Rule Event Saved Successfully');
        Utils.loader('#page-loader', 'hide');
        this.router.navigate(['landing', { outlets: { studio: 'rule-events-studio/' + res.body.name }}]);
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Save Failed');
        Utils.loader('#page-loader', 'hide');
        console.error(err);
      }
    );
  }

  updateRuleEvent(name: string, ruleEventsMetadata:RuleEventsMetadata ) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.UPDATE_RECOMMENDATION_RULE_EVENT_BY_NAME;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", name), currentAPIProperty.method, ruleEventsMetadata).subscribe(
      (res: any) => {
        this.toastr.success('Rule Event Updated Successfully');
        Utils.loader('#page-loader', 'hide');
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(['landing', { outlets: { studio: 'rule-events-studio/' + res.body.name }}]));
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Update Failed');
        Utils.loader('#page-loader', 'hide');
        console.error(err);
      }
    );
  }

  getAllRuleEvents(callback: any) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_RECOMMENDATION_RULE_EVENTS;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.ruleEventsList = res.body.items || [];
        console.log(res.body)
        Utils.loader('#page-loader', 'hide');
        callback();
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.log(err);
        callback(err);
      }
    );
  }

  showCodeMirror(value:boolean){
    this.showCodeMirrorPanel = value;
  }

  loadExecutesAtData() {
    this.ruleEventsMetadata.executesAt = ((this.recommendationCategoryMap[this.ruleEventsMetadata.category] || {}).executesAt || []);
  }

  formatConditionData() {
    try {
      this.conditionData = beautify.js(this.conditionData, { indent_size: 2, space_in_empty_paren: true });
    } catch (error) {
      this.toastr.error('Unable to parse JSON');
    }
  }

  formatActionData() {
    try {
      this.actionData = beautify.js(this.actionData, { indent_size: 2, space_in_empty_paren: true });
    } catch (error) {
      this.toastr.error('Unable to parse JSON');
    }
  }

}
