import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from  './common.service';
import { apiProperties } from '../utility/constants';
import { Utils } from './../utility/util';
import { Router } from '@angular/router';
declare var $: any;
import * as beautify from 'js-beautify';

import { ApiUtilService } from './api-util.service';
import { ObjectModelerService } from './object-modeler.service';
import { FormGroup } from '@angular/forms';

import { _get } from './../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class EventApiService {

  public submoduleList: string[];
  public connectorListMap: any = { submodule: {} };
  public connectorMetadata_Map: any = {};
  public eventAPIList: any[] = [];
  public eventAPIMetadata_Map: any = {};
  public internalFlowList: any[];
  public internalFlowListMap: any = { submodule: {} };
  public internalFlowMetadata_Map: any = {};
  public eventSchemaList: any[];
  public processList: any[];
  public processListMap: any = { submodule: {} };
  public allObjects: any;
  public allObjects_Map: object = {};
  public connectorStepOperatioList: string[] = null;
  public deleteEventApiSelectedRow: object = {};
  public isInternalFlow: boolean;
  public staticData: any = {
    headerBgColor: {
      "Business Logic": "#AC7AF0",
      "Child Flow": "#777777",
      "Database": "#FF9800",
      "Decision": "#ABBEBE",
      "FlowConnector": "#5D54D8",
      "FTP": "#4CAF50",
      "REST Service Call": "#E37777",
      "Internal Application Call": "#CDDC39",
      "Event Process Call": "#1c81c3bd",
      "Finally Block": "#FF5722",
      "WQM": "#41479B",
      "GCS": "#008D8D",
      "AWS CLI": "#ef6f12",
      "Power Shell": "#777777",
      "App Utils": "#8c700c"
    }
  }
  public HTTP_STATUS_CODE: any = {
		"OK": 200,
		"CREATED": 201,
		"ACCEPTED": 202,
		"NON_AUTHORITATIVE_INFORMATION": 203,
		"NO_CONTENT": 204,
		"RESET_CONTENT": 205,
		"PARTIAL_CONTENT": 206,
		"MULTI_STATUS": 207,
		"ALREADY_REPORTED": 208,
		"I_AM_USED": 226,

		"MULTIPLE_CHOICES": 300,
		"MOVED_PERMANENTLY": 301,
		"FOUND": 302,
		"SEE_OTHER": 303,
		"NOT_MODIFIED": 304,
		"TEMPORARY_REDIRECT": 307,
		"PERMANENT_REDIRECT": 308,
		
		"BAD_REQUEST": 400,
		"UNAUTHORIZED": 401,
		"PAYMENT_REQUIRED": 402,
		"FORBIDDEN": 403,
		"NOT_FOUND": 404,
		"METHOD_NOT_ALLOWED": 405,
		"NOT_ACCEPTABLE": 406,
		"PROXY_AUTHENTICATION_REQUIRED": 407,
		"REQUEST_TIMEOUT": 408,
		"CONFLICT": 409,
		"GONE": 410,
		"LENGTH_REQUIRED": 411,
		"PRECONDITION_FAILED": 412,
		"PAYLOAD_TOO_LARGE": 413,
		"REQUEST_URI_TOO_LONG": 414,
		"UNSUPPORTED_MEDIA_TYPE": 415,
		"REQUESTED_RANGE_NOT_SATISFIABLE": 416,
		"EXPECTATION_FAILED": 417,
		"I_AM_A_TEAPOT": 418,
		"UNPROCESSABLE_ENTITY": 422,
		"LOCKED": 423,
		"FAILED_DEPENDENCY": 424,
		"TOO_EARLY": 425,
		"UPGRADE_REQUIRED": 426,
		"TOO_MANY_REQUESTS": 429,
		"REQUEST_HEADER_FIELDS_TOO_LARGE": 431,
		"UNAVAILABLE_FOR_LEGAL_REASONS": 451,
		
		"INTERNAL_SERVER_ERROR": 500,
		"NOT_IMPLEMENTED": 501,
		"BAD_GATEWAY": 502,
		"SERVICE_UNAVAILABLE": 503,
		"GATEWAY_TIMEOUT": 504,
		"HTTP_VERSION_NOT_SUPPORTED": 505,
		"VARIANT_ALSO_NEGOTIATES": 506,
		"INSUFFICIENT_STORAGE": 507,
		"LOOP_DETECTED": 508,
		"BANDWIDTH_LIMIT_EXCEEDED": 509,
		"NOT_EXTENDED": 510,
		"NETWORK_AUTHENTICATION_REQUIRED": 511
	};
  public HTTP_STATUS_CODE_MAP: any = {};

  constructor(
    public apiUtilService: ApiUtilService,
    private _sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private router: Router,
    public objectModelerService: ObjectModelerService,
    private cs: CommonService
  ) {
    if(window.location.pathname.indexOf("internal-flow-studio") !== -1){
      this.isInternalFlow = true;
    } else {
      this.isInternalFlow = false;
    }
    Object.keys(this.HTTP_STATUS_CODE).forEach((key) => {
      this.HTTP_STATUS_CODE_MAP[this.HTTP_STATUS_CODE[key]] = key;
    });
  }

  getSubmoduleList() {
    this.submoduleList = [];
    /* let currentAPIProperty = apiProperties.GET_ACTIVE_PROJECT_SUBMODULE_NAMES;
    Utils.loader('#page-loader', 'show');
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, { submodule: this.apiUtilService.routedSubmoduleName }).subscribe(
      (res: any) => {
        this.submoduleList = res.body || [];
        Utils.loader('#page-loader', 'hide');
      },
      (err: any) => {
        console.error(err);
        this.toastr.error("Error occurred while fetching project's submodule list");
        Utils.loader('#page-loader', 'hide');
      } 
    ); */
  }
  
  getAllEventAPIs(successCallback: any, errorCallback: any) {
    let currentAPIProperty = apiProperties.GET_ALL_EVENT_APIS;
    this.eventAPIList = [];
    Utils.loader('#page-loader', 'show');
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        this.eventAPIList = res.body;
        successCallback(res);
      },
      (err: any) => {
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        Utils.loader('#page-loader', 'hide');
        errorCallback();
      } 
    );
  }

  getEventAPIByInputEventName(param: string) {
    return new Promise<void>((resolve, reject) => {
      Utils.loader('#page-loader', 'show');
        let currentAPIProperty = apiProperties.GET_EVENT_API_BY_INPUT_EVENT_NAME;
        let replaceVal = "{INPUT_EVENT_NAME}";
        if (this.isInternalFlow) {
          currentAPIProperty = apiProperties.GET_INTERNAL_FLOW_BY_ID;
          replaceVal = "{ID}";
        }
        this.apiUtilService.invokeAPI(currentAPIProperty.path.replace(replaceVal, param), currentAPIProperty.method).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            if (this.apiSettings.isCallFrmProcessFlow) this.eventAPIMetadata_Map[param] = res.body || {};
            resolve(res.body || {});
          },
          (err: any) => {
            err = err || {};
            if (err.status == 404 && (err.error || "").startsWith("Data not found for the id/inputEvent")) this.toastr.warning(err.error);
            else if (err.name == "HttpErrorResponse") this.toastr.error("Something went wrong!");
            Utils.loader('#page-loader', 'hide');
            reject(err);
          }
        );
    });
  }

  saveOrUpdateEventApi() {
    var apiMetadata = JSON.parse(JSON.stringify(this.apiSettings.selectedAPI));
    var isValidApiMetadata = () => {
      try {
        if((apiMetadata.name || '').trim() === '') throw 'Name is mandatory';
        if(!this.isInternalFlow && (apiMetadata.inputEvent || '').trim() === '') throw 'Input Event is mandatory';
        if(this.apiSettings.isCreateFlow) {
          let isExist = this.eventAPIList.find((api: any) => api.name === apiMetadata.name);
          if(isExist) throw 'API name already exists';
          if(!this.isInternalFlow){
            let isIEExist = this.eventAPIList.find((api: any) => api.inputEvent === apiMetadata.inputEvent);
            if(isIEExist) throw 'Input event already exists';
          }
        }
        if(this.isInternalFlow){
          if(!apiMetadata.metadata.path) throw 'API Path is mandatory';
          if(apiMetadata.metadata.path && !apiMetadata.metadata.path.startsWith("/")) throw 'API Path should start with \' / \'';
          if(!apiMetadata.metadata.method) throw 'Method is mandatory';
        }
        if((apiMetadata.metadata.input || '').trim() === '') throw 'Request is mandatory';
        if((apiMetadata.metadata.output || '').trim() === '') throw 'Response is mandatory';
        return true;
      } catch (error) {
        this.toastr.error(error);
        return false;
      }
    }
    if(isValidApiMetadata()){
      var _steps = apiMetadata.metadata.steps;
      var _currentStep = _steps.start;
      var updatedSteps = { childStepDetails: _steps.childStepDetails, start: _currentStep } as any;
      apiMetadata.executionStepList.forEach(function (flow: any, index: number) {
        var stepId = "step_" + (index + 1);
        _currentStep.nextStep = stepId;
        updatedSteps[stepId] = flow;
        _currentStep = flow;
      });
      _currentStep.nextStep = "end";
      updatedSteps.end = _steps.end;
      apiMetadata.metadata.steps = updatedSteps;
      delete apiMetadata.selectedStep;
      delete apiMetadata.executionStepList;
      delete apiMetadata.shapeDetails;
   
      if(!apiMetadata.hasOwnProperty("id")){ //Save Flow
        this.saveEventApi(apiMetadata);
      }else{ //Update Flow
        this.updateEventApi(apiMetadata.id, apiMetadata);
      }
    }else return;
  }
 
  saveEventApi(apiMetadata: any) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = this.isInternalFlow ? apiProperties.SAVE_INTERNAL_FLOW : apiProperties.SAVE_EVENT_API;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, apiMetadata).subscribe(
      (res: any) => {
        if(this.isInternalFlow) this.toastr.success('Internal Flow Saved Successfully');
        else this.toastr.success('Event API Saved Successfully');
        Utils.loader('#page-loader', 'hide');
        if(this.isInternalFlow) this.router.navigate(['landing', { outlets: { studio: 'internal-flow-studio/' + res.body.id }}]);
        else this.router.navigate(['landing', { outlets: { studio: 'event-api-studio/' + res.body.inputEvent }}]);
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Save Failed');
        Utils.loader('#page-loader', 'hide');
        console.error(err);
      }
    );
  }
 
  updateEventApi(id: any, apiMetadata: any) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = this.isInternalFlow ? apiProperties.UPDATE_INTERNAL_FLOW : apiProperties.UPDATE_EVENT_API;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", id), currentAPIProperty.method, apiMetadata).subscribe(
      (res: any) => {
        if(this.isInternalFlow) this.toastr.success('Internal Flow Updated Successfully');
        else this.toastr.success('Event API Updated Successfully');
        Utils.loader('#page-loader', 'hide');
        if(this.isInternalFlow && id != res.body.id) {
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate(['landing', { outlets: { studio: 'internal-flow-studio/' + res.body.id }}]));
        } else this.cs.updateMenuBreadCrumbDetails({ label: apiMetadata.name, parentListComponentURL: this.isInternalFlow ? 'internal-flow-list' : 'event-api-list'});
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Update Failed');
        Utils.loader('#page-loader', 'hide');
        console.error(err);
      }
    );
  }

  deleteEventAPI(id: any, successCallback: any, errorCallback: any) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.DELETE_EVENT_API_BY_ID;
      this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", id), currentAPIProperty.method).subscribe(
        (res: any) => {
          Utils.loader('#page-loader', 'hide');
          successCallback(res);
        },
        (err: any) => {
          this.toastr.error((err.error || {}).message || 'Delete Failed');
          Utils.loader('#page-loader', 'hide');
          errorCallback(err);
        }
      );
  }

  getAllConnectorList(moduleName?: string, frmSelect?: boolean) {
    return new Promise<void>((resolve, reject) => {
      var connectorList = null, submoduleConnectorListMap = this.connectorListMap.submodule;
      if (frmSelect) {
        this.apiSettings.selectedAPIStep.configured_id = "";
        this.apiSettings.selectedAPIStep.operationId = "";
      }
      if (moduleName) connectorList = submoduleConnectorListMap[moduleName];
      else connectorList = this.connectorListMap["#"];
      if (!connectorList) {
        let currentAPIProperty = apiProperties.GET_ALL_API_CONNECTORS;
        Utils.loader('#page-loader', 'show');
        this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, moduleName ? { submodule: moduleName } : null).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            if (moduleName) connectorList = submoduleConnectorListMap[moduleName] = res.body || [];
            else connectorList = this.connectorListMap["#"] = res.body || [];
            this.apiSettings.updateConnectorMap(connectorList);
            resolve();
          },
          (err: any) => {
            console.error(err);
            if (err.name == "HttpErrorResponse") this.toastr.error("Something went wrong!");
            Utils.loader('#page-loader', 'hide');
            reject(err);
          }
        );
      } else {
        this.apiSettings.updateConnectorMap(connectorList);
        resolve();
      }
    });
  }

  getConnectorById(configuredId: string, moduleName?: string) {
    return new Promise((resolve, reject) => {
      let currentAPIProperty = apiProperties.GET_API_CONNECTOR_BY_ID;
        Utils.loader('#page-loader', 'show');
        this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", configuredId), currentAPIProperty.method, moduleName ? { submodule: moduleName } : null).subscribe(
          (res: any) => {
            this.connectorMetadata_Map[configuredId] = res.body;
            Utils.loader('#page-loader', 'hide');
            resolve(res.body);
          },
          (err: any) => {
            console.error(err);
            if (err.name == "HttpErrorResponse") this.toastr.error("Something went wrong!");
            Utils.loader('#page-loader', 'hide');
            reject(err);
          }
        );
    });
  }

  getAllEventSchemaList(successCallback?: any, errorCallback?: any) {
    let currentAPIProperty = apiProperties.GET_ALL_EVENT_SCHEMA;
    Utils.loader('#page-loader', 'show');
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        this.eventSchemaList = res.body || [];
        if(successCallback) successCallback(res);
      },
      (err: any) => {
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        Utils.loader('#page-loader', 'hide');
        if(errorCallback) errorCallback(err);
      }
    );
  }

  getConnectorOperationList(connectorType: string) {
    return new Promise<void>((resolve, reject) => {
      var getOperations = (debuggerId?: string) => {
        Utils.loader('#page-loader', 'show');
        let currentAPIProperty = apiProperties.GET_CONNECTOR_OPERATIONS;
        var reqHeaders = {};
        if (debuggerId) reqHeaders = { "debugger-ref": debuggerId, "is-local-metadata-execution": "TRUE" };
        this.apiUtilService.invokeCloudAPI(currentAPIProperty.path.replace("{CONNECTOR_TYPE}", connectorType), currentAPIProperty.method, null, reqHeaders).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            this.connectorStepOperatioList = res.body || [];
            resolve();
          },
          (err: any) => {
            console.error(err);
            if (err.name == "HttpErrorResponse") this.toastr.error("Something went wrong!");
            Utils.loader('#page-loader', 'hide');
            reject(err);
          }
        );
      }
      let devMode = localStorage.getItem("DEV-MODE") || 'cloud';
      if (devMode == "local") {
        let debuggerRefProperty = apiProperties.GET_DEBUGGER_REF;
        this.apiUtilService.invokeLocalAPI(debuggerRefProperty.path, debuggerRefProperty.method).subscribe(
          (res: any) => {
            getOperations(res.body.debuggerId);
          },
          (err: any) => {
            if (err.name == "HttpErrorResponse") {
              this.toastr.error("Something went wrong!");
            }
            Utils.loader('#page-loader', 'hide');
          }
        );
      } else getOperations();
    });
  }

  getEventSchemaByName(name: string, successCallback: any, errorCallback: any) {
    let currentAPIProperty = apiProperties.GET_EVENT_SCHEMA_BY_NAME;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{NAME}", name), currentAPIProperty.method).subscribe(
      (res: any) => successCallback(res),
      (err: any) =>  errorCallback(err)
    );
  }

  getAllInternalFlowList(moduleName?: string, frmSelect?: boolean) {
    return new Promise<void>((resolve, reject) => {
      this.internalFlowList = null;
      if (frmSelect) this.apiSettings.selectedAPIStep.eventFlowId = "";
      var submoduleInternalFlowListMap = this.internalFlowListMap.submodule;
      if (moduleName) this.internalFlowList = submoduleInternalFlowListMap[moduleName];
      else this.internalFlowList = this.internalFlowListMap["#"];
      if (!this.internalFlowList) {
        let currentAPIProperty = apiProperties.GET_ALL_INTERNAL_FLOWS;
        Utils.loader('#page-loader', 'show');
        this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, moduleName ? { submodule: moduleName } : null).subscribe(
          (res: any) => {
            if (moduleName) this.internalFlowList = submoduleInternalFlowListMap[moduleName] = res.body || [];
            else this.internalFlowList = this.internalFlowListMap["#"] = res.body || [];
            this.internalFlowList.forEach((obj: any) => this.internalFlowMetadata_Map[obj.idRef] = obj);
            Utils.loader('#page-loader', 'hide');
            resolve();
          },
          (err: any) => {
            console.error(err);
            if (err.name == "HttpErrorResponse") this.toastr.error("Something went wrong!");
            Utils.loader('#page-loader', 'hide');
            reject(err);
          }
        );
      } else resolve();
    });
  }

  getAllProcessList(moduleName?: string, frmSelect?: boolean) {
    return new Promise<void>((resolve, reject) => {
      this.processList = null;
      if (frmSelect) this.apiSettings.selectedAPIStep.processName = "";
      var submoduleProcessListMap = this.processListMap.submodule;
      if (moduleName) this.processList = submoduleProcessListMap[moduleName];
      else this.processList = this.processListMap["#"];
      if (!this.processList) {
        let currentAPIProperty = apiProperties.GET_ALL_PROCESS_METADATA;
        Utils.loader('#page-loader', 'show');
        this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, moduleName ? { submodule: moduleName } : null).subscribe(
          (res: any) => {
            if (moduleName) this.processList = submoduleProcessListMap[moduleName] = res.body || [];
            else this.processList = this.processListMap["#"] = res.body || [];
            Utils.loader('#page-loader', 'hide');
            resolve();
          },
          (err: any) => {
            console.error(err);
            if (err.name == "HttpErrorResponse") this.toastr.error("Something went wrong!");
            Utils.loader('#page-loader', 'hide');
            reject(err);
          }
        );
      } else resolve();
    });
  }

  resetMetadataRefs() {
    this.submoduleList = null;
    this.eventSchemaList = null;
    this.allObjects = null;
    this.connectorListMap = { submodule: {} };
    this.connectorMetadata_Map = {};
    this.internalFlowListMap = { submodule: {} };
    this.internalFlowMetadata_Map = {};
    this.eventAPIMetadata_Map = {};
    this.processListMap = { submodule: {} };
    this.connectorStepOperatioList = null;
  }

  apiSettings: any = {
    eventApiService: this,
    _apiUtilService: this.apiUtilService,
    _toastr: this.toastr,
    isCreateFlow: null,
    open: false, 
    apiPath: "",
    curl: "",
    pathParmList: [],
    selectedAPI: {},
    selectedAPIStep: {},
    currentInternalServiceCallAPIMetada: {},
    currentRestCallConnectorMetadata: {},
    currentRestCallOperation_Map: {},
    openAdvSettings: false,
    isCallFrmProcessFlow: false,
    openRecAdvSettings : false,
    isApiQuickTest: false,
    connectorMap: {"Database": [], "FTP": [], "REST Service Call": [], "WQM": [{id: 'PLATFORM_WQM', name: 'Application WQM'}], "GCS": [], "AWS CLI": [], "Power Shell": [] },
    defaultEventApiMetadata: {inputEvent: '', executionStepList: [], metadata: {method: 'POST', input: '', output: '', steps: {start: {nextStep: "end"}, end:{}, childStepDetails: []}}},
    simulator: { request: { metadata: '{}', parsedObject: {}, headers: '{}' }, response: { metadata: '', parsedObject: {}, form: new FormGroup({}), model: {}, modelMetadata: '', fields: [] } },
    inputModelConfigDetails: {},
    showEventSchemaSimulator: false,
    inputModelCardinality: "object",
    submoduleSupportedSteps: ["REST Service Call", "Internal Application Call", "Event Process Call"],
    init: async function(userData: any) {
      Utils.loader('#page-loader', 'show');
      this.reset();
      this.open = true;
      this.openRecAdvSettings = userData.openRecAdvSettings || false;
      this.isCallFrmProcessFlow = userData.isCallFrmProcessFlow;
      if(this.isCallFrmProcessFlow) {
        this.openAdvSettings =  false ;
        this.eventApiService.isInternalFlow = false;
      }
      else this.openAdvSettings = true;
      
      var renderAPISettings = () => {
        Utils.loader('#page-loader', 'hide');
        setTimeout(() => {
          let apiSettingsCont = document.getElementsByClassName("api-settings-container")[0];
          if(apiSettingsCont) apiSettingsCont.scrollIntoView();
        }, 0);
      }
      let apiMetadata = null, param = this.eventApiService.isInternalFlow ? userData.id : userData.inputEvent;
      if (userData.isCallFrmProcessFlow) apiMetadata = this.eventApiService.eventAPIMetadata_Map[param];
      if (!apiMetadata) apiMetadata = await this.eventApiService.getEventAPIByInputEventName(param);
      this.selectedAPI = this.updateActionFlow(apiMetadata);
      if (this.isCallFrmProcessFlow) {
        let thinLineColor = userData.randomColor;
        this.selectedAPI['shapeDetails'] = { thinLineColor, boxShadow: this.eventApiService._sanitizer.bypassSecurityTrustStyle(`1px 0px 3px  ${thinLineColor}`) };
      }
      if (this.isCallFrmProcessFlow) {
        if (!this.eventApiService.submoduleList) this.eventApiService.getSubmoduleList();
        if (!this.eventApiService.allObjects) this.getObjects();
        if (!this.eventApiService.eventSchemaList) this.eventApiService.getAllEventSchemaList();
        if (!this.eventApiService.internalFlowListMap["#"]) this.eventApiService.getAllInternalFlowList();
        if (!this.eventApiService.connectorListMap["#"]) this.eventApiService.getAllConnectorList();
        if (!this.eventApiService.processListMap["#"]) this.eventApiService.getAllProcessList();
        if (!this.eventApiService.connectorStepOperatioList) this.eventApiService.getConnectorOperationList("Power Shell");
      } else {
        this.eventApiService.getSubmoduleList();
        this.getObjects();
        this.eventApiService.getAllEventSchemaList();
        this.eventApiService.getAllInternalFlowList();
        this.eventApiService.getAllConnectorList();
        this.eventApiService.getAllProcessList();
        this.eventApiService.getConnectorOperationList("Power Shell");
      }
      renderAPISettings();
    },
    reset: function() {
      this.curl = "";
      this.selectedAPI = {};
      this.selectedAPIStep = {};
      this.currentInternalServiceCallAPIMetada = {};
      this.currentRestCallConnectorMetadata = {};
      this.currentRestCallOperation_Map = {};
      this.openAdvSettings = false;
      this.isCallFrmProcessFlow = false;
      this.isCreateFlow = false;
    },
    closeAPISettings: function() {
      this.reset();
      this.open = false;
    },
    initCreateNewEventAPI: function() {
      this.selectedAPI = {};
      this.selectedAPIStep = {};
      this.isCreateFlow = true;
      this.isCallFrmProcessFlow = false;
      this.openAdvSettings = true;
      var renderAPISettings = () => {
        this.selectedAPI = JSON.parse(JSON.stringify(this.defaultEventApiMetadata));
      }
      if (!this.eventApiService.submoduleList) this.eventApiService.getSubmoduleList();
      if (!this.eventApiService.allObjects) this.getObjects();
      if (!this.eventApiService.eventSchemaList) this.eventApiService.getAllEventSchemaList();
      if (!this.eventApiService.internalFlowListMap["#"]) this.eventApiService.getAllInternalFlowList();
      if (!this.eventApiService.connectorListMap["#"]) this.eventApiService.getAllConnectorList();
      if (!this.eventApiService.processListMap["#"]) this.eventApiService.getAllProcessList();
      if (!this.eventApiService.connectorStepOperatioList) this.eventApiService.getConnectorOperationList("Power Shell");
      renderAPISettings();
    },
    getObjects: function () {
      Utils.loader('#page-loader', 'show');
      this.eventApiService.objectModelerService.getAllObjects((res: any) => {
        Utils.loader('#page-loader', 'hide');
        this.eventApiService.allObjects = res.body || [];
        this.eventApiService.allObjects.sort((a, b) => a.model.toLowerCase() > b.model.toLowerCase());
        this.eventApiService.allObjects_Map = {};
        this.eventApiService.allObjects.forEach((obj) => this.eventApiService.allObjects_Map[obj.model] = obj);
      }, (err: any) => {
        console.error(err);
        Utils.loader('#page-loader', 'hide');
      });
    },
    updateActionFlow: function(actionFlow: any){
      actionFlow.executionStepList = [];
      actionFlow.metadata.childStepDetails = actionFlow.metadata.childStepDetails || [];
      var steps = actionFlow.metadata.steps;
      var currentStep = steps.start;
      while(currentStep && currentStep.nextStep != "end"){
        currentStep = steps[currentStep.nextStep];
        actionFlow.executionStepList.push(currentStep);
      }
      return actionFlow;
    },
    updateConnectorMap: function(connectorList: any) {
      this.connectorMap = {"Database": [], "FTP": [], "REST Service Call": [], "WQM": [{id: 'PLATFORM_WQM', name: 'Application WQM'}], "GCS": [], "AWS CLI": [], "Power Shell": [] };
      connectorList.forEach((connection: any) => this.connectorMap[connection.type == "Application" ? "REST Service Call" : connection.type].push(connection) );
    },
    setSelectedAPIStep: async function(step: any, event: Event) {
      this.resetSelectedAPIStep();
      var currenrTargetStep = event.currentTarget;
      $(currenrTargetStep).addClass('selected-step');
      this.selectedAPIStep = step;
      if (this.submoduleSupportedSteps.includes(this.selectedAPIStep.type)) this.selectedAPIStep.submodule = this.selectedAPIStep.submodule || "";
      if (this.selectedAPIStep.type === 'Internal Application Call') {
        if (this.selectedAPIStep.submodule) {
          this.eventApiService.internalFlowList = this.eventApiService.internalFlowListMap.submodule[this.selectedAPIStep.submodule];
          if(!this.eventApiService.internalFlowList) await this.eventApiService.getAllInternalFlowList(this.selectedAPIStep.submodule);
        }
        else this.eventApiService.internalFlowList = this.eventApiService.internalFlowListMap["#"];
        if (!Utils.isNullOrEmpty(this.selectedAPIStep.eventFlowId)) this.loadCurrentInternalServiceCallAPIMetada();
      } else if (this.selectedAPIStep.type === 'Event Process Call') {
        if (this.selectedAPIStep.submodule) {
          this.eventApiService.processList = this.eventApiService.processListMap.submodule[this.selectedAPIStep.submodule];
          if(!this.eventApiService.processList) await this.eventApiService.getAllProcessList(this.selectedAPIStep.submodule);
        }
        else this.eventApiService.processList = this.eventApiService.processListMap["#"];
        this.loadCurrentProcessStartEvent();
      } else if (this.selectedAPIStep.type === 'Power Shell') {
        if(!this.eventApiService.connectorStepOperatioList) await this.eventApiService.getConnectorOperationList("Power Shell");
      } else if (this.connectorMap[this.selectedAPIStep.type] != null) {
        let connectorList = null;
        if (this.selectedAPIStep.submodule) connectorList = this.eventApiService.connectorListMap.submodule[this.selectedAPIStep.submodule];
        else connectorList = this.eventApiService.connectorListMap["#"];
        if (connectorList) this.updateConnectorMap(connectorList);
        else this.eventApiService.getAllConnectorList(this.selectedAPIStep.submodule);
        if (!Utils.isNullOrEmpty(this.selectedAPIStep.configured_id)) this.loadCurrentRestCallConnectorMetadata(false);
      }
      setTimeout(() => $(currenrTargetStep)[0].scrollIntoViewIfNeeded(), 0);
    },
    resetSelectedAPIStep: function() {
      this.selectedAPIStep = {};
      $(".actionflow-step-child").removeClass('selected-step');
      $(".actionflow-step").removeClass('selected-step');
    },
    openAdvancedSettings: function() {
      this.openAdvSettings = !this.openAdvSettings;
      setTimeout(() => {
        let apiWrkFlowCont = document.getElementsByClassName("api-workflow-container")[0];
        if(apiWrkFlowCont) apiWrkFlowCont.scrollIntoView();
      }, 100);
    },
    loadCurrentInternalServiceCallAPIMetada: function() {
      this.currentInternalServiceCallAPIMetada = {};
      let apiId: string = this.selectedAPIStep.eventFlowId;;
      if (apiId) this.currentInternalServiceCallAPIMetada = this.eventApiService.internalFlowMetadata_Map[apiId] || { input: "", output: "" };
    },
    loadCurrentRestCallConnectorMetadata: async function(frmSelect: boolean) {
      this.currentRestCallConnectorMetadata = {};
      if(frmSelect) this.selectedAPIStep.operationId = '';
      let configured_id: string = this.selectedAPIStep.configured_id;
      if(this.selectedAPIStep.type == "REST Service Call" && configured_id) {
        this.currentRestCallConnectorMetadata = this.eventApiService.connectorMetadata_Map[configured_id];
        if (!this.currentRestCallConnectorMetadata) 
          this.currentRestCallConnectorMetadata = await this.eventApiService.getConnectorById(configured_id, this.selectedAPIStep.submodule);
        ((this.currentRestCallConnectorMetadata.conversionMap || {}).operation || []).forEach((op: any) => this.currentRestCallOperation_Map[op.operationId] = op);
      }
    },
    loadCurrentProcessStartEvent: function() {
      let processName = this.selectedAPIStep.processName;
      this.selectedAPIStep.eventName = processName == "" ? "" : ((this.eventApiService.processList.find((element: any) => processName === element.name) || {}).startEvent) || "";
    },
    parseJSONViewerSimulatorObject: function(type: string) {
      try {
        this.simulator[type].parsedObject = JSON.parse(this.simulator[type].metadata);
      } catch (error) { 
        this.simulator[type].parsedObject = {undefined};
        console.error('Error occurred while parsing ' + type + ' object');
      }
    },
    loadSimulatorFormlyModelMetadata: function () {
      try {
        this.simulator.response.modelMetadata = JSON.stringify(this.simulator.response.model, null, "\t");
      } catch (error) { 
        this.simulator.response.modelMetadata = {undefined};
        console.error('Error occurred while loading response schema form model');
      }
    },
    beautifySimulatorReqJson: function(obj: any, key: string) {
      try {
        this.simulator.request[key] = beautify.js(obj, { indent_size: 2, space_in_empty_paren: true });
      } catch (error) {
        this.toastr.error('Unable to parse JSON');
      }
    },
    showAPISimulatorModal: function(isApiQuickTest: boolean, requestObject: any){
      this.isApiQuickTest = isApiQuickTest;
      this.simulator = { request: { metadata: JSON.stringify(requestObject, null, 2), parsedObject: requestObject, headers: '{}' }, response: { metadata: '', parsedObject: {}, form: new FormGroup({}), model: {}, modelMetadata: '', fields: [] } };
      this.showEventSchemaSimulator = false;
      this.buildURL();
      $("#raw-input-tab").click();
      $("#raw-output-tab").click();
      $('#apiSimulatorModal').modal('show');
    },
    buildURL: function(){
      let URL = null; 
      this.pathParmList = [];
      if(this.eventApiService.isInternalFlow) {
       URL = this.selectedAPI.metadata.path || '';
       URL.split("/").forEach(text => {
         if(text.startsWith("{") && text.endsWith("}")){
          this.pathParmList.push({key: text.substring(1, text.length -1), value: ""});
         }
       });
      }else if(!this.isApiQuickTest){
        URL = apiProperties.SUBMIT_EVENT.path.replace('/apiEngine', '');
      } else {
        URL = apiProperties.EXECUTE_EVENT_FLOW.path.replace('/apiEngine', '').replace('{INPUT_EVENT}', this.selectedAPI.inputEvent);
      }
      this.apiPath = URL;
    },
    updateURL: function(){
      if(this.eventApiService.isInternalFlow) {
        var index = 0;
        this.apiPath = (this.selectedAPI.metadata.path || '').split("/").map(text => text.startsWith("{") && text.endsWith("}") && this.pathParmList[index].value != "" ? encodeURIComponent(this.pathParmList[index++].value) : text).join("/");
      }
    },
    getApiPathAndMethod: function(){
      let currentAPIProperty = !this.isApiQuickTest ? apiProperties.SUBMIT_EVENT : apiProperties.EXECUTE_EVENT_FLOW;
      let apiPath = null;
        if(this.eventApiService.isInternalFlow){
          currentAPIProperty = apiProperties.EXECUTE_INTERNAL_FLOW;
          apiPath = currentAPIProperty.path + this.apiPath;
        } else {
          apiPath = currentAPIProperty.path.replace("{INPUT_EVENT}", this.selectedAPI.inputEvent);
        }
        const method = this.eventApiService.isInternalFlow ? this.selectedAPI.metadata.method : currentAPIProperty.method;
        return {apiPath, method};
    },
    generateCurl: function(callback: any){
      var apiDetails = this.getApiPathAndMethod();
      var reqHeaders = { "Content-Type": "application/json" };
      var requestData = null;
      try{
        requestData = JSON.parse(this.simulator.request.metadata);
        if(apiDetails.method == "GET"){
          var queryParamList = Object.keys(requestData).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(requestData[key]));
          if(queryParamList.length > 0) apiDetails.apiPath += queryParamList.join("&");
          requestData = null;
        }
      }catch(e){
        this._toastr.error('Please enter valid request body');
      }

      this.curl = "curl -X " + apiDetails.method + " \\" +
                  "\n'" + this._apiUtilService.getAPIExecutionURL() + apiDetails.apiPath + "' \\" + 
                  "\n" + Object.keys(reqHeaders).map(key => "-H '" + key + ": " + reqHeaders[key] + "' \\").join("\n") +
                  (requestData ? "\n-d '" + JSON.stringify(requestData, null, "  ") + "'" : "");
      callback();
    },
    triggerSubmitEvent: function(isDebugRequired: boolean){
      this.showEventSchemaSimulator = false;
      var requestData = null;
      try{
        requestData = JSON.parse(this.simulator.request.metadata);
      }catch(e){
        this._toastr.error('Please enter valid request body');
        return;
      }
      Utils.loader('#page-loader', 'show');

      var triggerSubmitEvent = (debuggerRefId: string = null) => {
        const apiDetails = this.getApiPathAndMethod();
        var reqHeaders: any = JSON.parse(this.simulator.request.headers || '{}');
        if(debuggerRefId) reqHeaders['debugger-ref'] = debuggerRefId;
        
        this._apiUtilService.invokeAPI(apiDetails.apiPath, apiDetails.method, requestData, reqHeaders).subscribe(
          (res: any) => {
            var response: any = res.body;
            this.showEventSchemaSimulator = true;
            this.simulator.response = {
              metadata: JSON.stringify(response, null, 4),
              parsedObject: response,
              status: res.status,
              statusMessage: this.eventApiService.HTTP_STATUS_CODE_MAP[res.status]
            };
            var _schemaFormCallback = () => {
              this.loadSimulatorFormlyModelMetadata();
              setTimeout(() => {
                this.replace$this(this.simulator.response, JSON.parse(JSON.stringify(this.simulator.response.model)));
              });
              Utils.loader('#page-loader', 'hide');
            };
            if (apiDetails.apiPath.endsWith("/submitEvent")) {
              this.simulator.response.fields = (response.schema || {}).userInput;
              this.simulator.response.model = (response.schema || {}).processContext;
              _schemaFormCallback();
            } else {
              this.simulator.response.model = response;
              if (!this.eventApiService.isInternalFlow && this.selectedAPI.inputEvent) {
                this.eventApiService.getEventSchemaByName(this.selectedAPI.inputEvent, (res: any) => {
                  this.simulator.response.fields = (res.body || {}).userInput;
                  _schemaFormCallback();
                }, (err: any) => {
                console.error(err);
                  Utils.loader('#page-loader', 'hide');
                });
              } else Utils.loader('#page-loader', 'hide');
            }
          },
          (err: any) => {
            let errorObj = err.error;
            if(errorObj || errorObj.status === "ERROR") 
              this.simulator.response = {
                metadata: JSON.stringify(errorObj, null, 4),
                parsedObject: errorObj,
                status: err.status,
                statusMessage: this.eventApiService.HTTP_STATUS_CODE_MAP[err.status]
              };
            else this._toastr.error('Something went wrong');
            console.error(err);
            Utils.loader('#page-loader', 'hide');
          }
        );
      };

      if (isDebugRequired && localStorage.getItem("DEV-MODE") == "cloud" && localStorage.getItem("IS_CLOUD_DEBUG_ENABLED") == "true"){
        let debuggerRefProperty = apiProperties.GET_DEBUGGER_REF;
        this._apiUtilService.invokeLocalAPI(debuggerRefProperty.path, debuggerRefProperty.method).subscribe(
          (res: any) => {
            triggerSubmitEvent(res.body.debuggerId);
          },
          (err: any) => {
            this._toastr.error('Invalid port number please check.');
            $("#config-dev-mode-modal").modal("show");
            Utils.loader('#page-loader', 'hide');
          }
        );
      } else triggerSubmitEvent();
    },
    replace$this: function ($this: any, processInstance: any, fieldsName = "fields") {
      if (!$this.hasOwnProperty(fieldsName)) {
        throw new Error(
          "Cannot find the property of '" +
            fieldsName +
            "'. It is the data type of 'FormlyFieldConfig[]'"
        );
      }
      const fields = $this[fieldsName];
  
      const _replace = (fld: any) => {
        if (fld) {
          if (Array.isArray(fld)) {
            fld.forEach(_replace);
          } else if (typeof fld === "object") {
            const keys = Object.keys(fld);
  
            keys.forEach(key => {
              if (key === "templateOptions") {
                const templateOptions = fld[key];
                if (templateOptions) {
                  const templateKeys = Object.keys(templateOptions);
                  // 'on' handler
                  const onKeys = templateKeys.filter((key) => key.startsWith("on"));
                  onKeys.forEach(onKey => {
                    const handler = templateOptions[onKey];
                    if (
                      typeof handler === "string" &&
                      handler.includes("$this")
                    ) {
                      templateOptions["_this"] = $this;
                      templateOptions[onKey] = templateOptions[onKey].replaceAll(
                        "$this",
                        "this.to._this"
                      );
  
                      templateOptions[onKey] = templateOptions[onKey].replaceAll(
                        "ms-authorize",
                        "msAuthorize"
                      );
                    }
                  });
  
                  //addonRight
                  if (templateKeys.includes('addonRight')) {
                    const addonRight: any = templateOptions.addonRight;
                    if (addonRight && addonRight.hasOwnProperty('text')) {
                      const addonRightText = addonRight.text;
                      if (addonRightText && typeof addonRightText === 'string' && addonRightText.startsWith('$this.model')) {
                        const addonRightProp = addonRightText.replace('$this.model.', '');
                        templateOptions.addonRight.text = _get(processInstance, addonRightProp);
                      }
                    }
                  }
                }
              } else if (key === 'type') {
                const fieldType = fld[key];
                if (fieldType === 'custom-iframe') {
                  const src = _get(processInstance, fld['defaultValue']);
                  fld['templateOptions'] = fld['templateOptions'] || {};
                  fld['templateOptions']['attributes'] = fld['templateOptions']['attributes'] || {};
                  fld['templateOptions']['attributes']['src'] = src;
                } else if (fieldType === 'file') {
                  fld['templateOptions'] = fld['templateOptions'] || {};
                  fld['templateOptions']['uploadCallback'] = $this.uploadCallback(fld['key']).bind($this);
                } else if (fieldType === 'refresh-button') {
                  fld['templateOptions'] = fld['templateOptions'] || {};
                  fld['templateOptions']['attributes'] = fld['templateOptions']['attributes'] || {};
                  fld['templateOptions']['attributes']['refreshCallback'] = $this.refreshCallback().bind($this);
                } else if (fieldType === 'select') {
                  const options = _get(fld, 'templateOptions.options');
                  if (options && typeof options === 'string') {
                    let formlyOptions: any[] = [];
                    if (options.startsWith('this.model.')) {
                      const prop = options.replace('this.model.', '');
                      if (prop) {
                        const optionsSource = _get(processInstance, prop);
                        if (optionsSource && Array.isArray(optionsSource)) {
                          formlyOptions = JSON.parse(JSON.stringify(optionsSource));
                        }
                      }
                    }
                    fld['templateOptions']['options'] = formlyOptions;
                  }
                } else if (fieldType === 'auto-refresh') {
                  fld['templateOptions'] = fld['templateOptions'] || {};
                  fld['templateOptions']['attributes'] = fld['templateOptions']['attributes'] || {};
                  fld['templateOptions']['attributes']['autoRefreshCallback'] = $this.autoRefreshCallback().bind($this);
                }
              } else if(key ==='template') {
                const templateContent = fld[key];
                if (templateContent.startsWith('$this.model.')) {
                  const property = templateContent.replace('$this.model.', '');
                  if (this.simulator.response.model) {
                    fld[key] = this.simulator.response.model[property];
                  }
                }
              } else {
                _replace(fld[key]);
              }
            });
          }
        }
      };
  
      _replace(fields);
    },
    uploadCallback(field: string) {
      return (content: any) => {
        this.model[field] = JSON.parse(content);
      };
    },
    openActionFlowReqModal: function(){
      if(this.selectedAPI.metadata.method == "GET")
        this.openActFlowReqConfigModal();
      else
        this.openActFlowReqCardinalityModal();
    },
    openActFlowReqConfigModal: function(){
      this.inputModelConfigDetails = {};
      (this.eventApiService.allObjects_Map[this.selectedAPI.metadata.input].attributes || []).forEach((attr) => { 
        this.inputModelConfigDetails[attr.name] = (this.selectedAPI.metadata.inputConfigDetails || {})[attr.name] || "optional"; 
      });
      $("#request_config_modal").modal("show");
    },
    saveActFlowReqConfigModal: function(){
      this.selectedAPI.metadata.inputConfigDetails = JSON.parse(JSON.stringify(this.inputModelConfigDetails));
      this._toastr.success("Applied Successfully..!!");
    },
    openActFlowReqCardinalityModal: function(){
      this.inputModelCardinality = this.selectedAPI.metadata.inputModelCardinality || "object";
      $("#req_cardinality_config_modal").modal("show");
    },
    saveActFlowReqCardinalityModal: function(){
      this.selectedAPI.metadata.inputModelCardinality = this.inputModelCardinality;
      this._toastr.info("Applied Successfully..!!");
    },
    openActFlowRespConfigModal: function(){
      this.outputModelCardinality = this.selectedAPI.metadata.outputCardinality || "object";
      $("#response_config_modal").modal("show");
    },
    saveActFlowRespConfigModal: function(){
      this.selectedAPI.metadata.outputCardinality = this.outputModelCardinality;
      this._toastr.info("Applied Successfully..!!");
    },
    exportActionFlow: function(exportType: string){
      Utils.loader('#page-loader', 'show');
      let currentAPI = apiProperties.EXPORT_INTERNAL_FLOWS;
      this._apiUtilService.invokeAPI(currentAPI.path.replace("{TYPE}", exportType), currentAPI.method, { "origin": location.origin }).subscribe(
        (res: any) => {
          var element = document.createElement('a');
          if(exportType == "swagger") {
            element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(JSON.stringify(res.body)));
            element.setAttribute('download', "API-Specs.json");
          } else {
            element.setAttribute('href', "data:text/yaml;charset=UTF-8," + encodeURIComponent(res.body));
            element.setAttribute('download', "API-Specs.yml");
          }
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          Utils.loader('#page-loader', 'hide');
        },
        (err: any) => {
          console.log(err);
          Utils.loader('#page-loader', 'hide');
        }
      );
    }
  }

  decision: any = {
    _toastr: this.toastr,
    actionflow: null,
    decisionList: [],
    childStepDetails: [],
    showDialog: function(actionflow: any) {
      this.actionflow = actionflow;
      this.decisionList = JSON.parse(JSON.stringify(actionflow.selectedAPIStep.decisionList || []));
      this.childStepDetails = actionflow.selectedAPI.metadata.steps.childStepDetails || [];
      $("#eventAPIDecisionModal").modal("show");
    },
    save: function(){
      try{
        this.decisionList.forEach((decision: any, index: number) => {
          if(Utils.isNullOrEmpty(decision.key)){
            throw "Please enter key in decision " + (index + 1);
          }else if(Utils.isNullOrEmpty(decision.operation)){
            throw "Please select operation in decision " + (index + 1); 
          }else if(Utils.isNullOrEmpty(decision.value)){
            throw "Please enter value in decision " + (index + 1);
          }else if(Utils.isNullOrEmpty(decision.childFlowId)){
            throw "Please select childflow in decision " + (index + 1);
          }
        });
        this.actionflow.selectedAPIStep.decisionList = JSON.parse(JSON.stringify(this.decisionList));
        $("#eventAPIDecisionModal").modal("hide");
      }catch(e){
        this._toastr.error(e);
      }
    }
  }

  contextMenuActions: any = {
    _apiSettings: this.apiSettings,
    renderFinallyBlockDtls: function () {
      this._apiSettings.selectedAPI.metadata.finallyBlockDtls = {name: "Finally Block Script", isFinally: true, type: "Finally Block Script", scriptType: "JavaScript"}
    },
    removeFinallyBlockDtls: function () {
      delete this._apiSettings.selectedAPI.metadata.finallyBlockDtls;
      this._apiSettings.selectedAPIStep = {};
    },
    getNewStepObject: function (stepType: string, stepList?: any[]) {
      switch (stepType) {
        case 'Business Logic':
          return { name: "Business Logic", type: "Business Logic", scriptType: "JavaScript" };
        case 'Child Flow':
          return { id: Utils.getRandomId(), name: "Child Flow", type: "Child Flow", steps: [{ name: "Child Flow - " + (stepList.length + 1), type: "ChildFlowStart" }] };
        case 'Database':
          return { name: "Database", type: "Database", configured_id: ""};
        case 'Decision':
          return { name: "Decision", type: "Decision" };
        case 'Flow Connector':
          return { name: "Flow Connector", type: "FlowConnector", childFlowId: "" }
        case 'FTP':
          return { name: "FTP", type: "FTP", configured_id: "", operationName: "" };
        case 'Internal Flow':
          return { name: "Internal Flow", type: "Internal Application Call", submodule: "", eventFlowId: "" };
        case 'REST Service Call':
          return { name: "REST Service Call", type: "REST Service Call", submodule: "", configured_id: "", operationId: "" };
        case 'Event Process Call':
          return { name: "Event Process Call", type: "Event Process Call", submodule: "", processName: "" };
        case 'Finally Block':
          return { name: "Finally Block Script", isFinally: true, type: "Finally Block Script", scriptType: "JavaScript" };
        case 'WQM':
          return { name: "WQM", type: "WQM", configured_id: "PLATFORM_WQM", operationName: "" };
        case 'GCS':
          return { name: "GCS", type: "GCS", configured_id: "", operationName: "" };
        case 'AWS CLI':
          return { name: "AWS CLI", type: "AWS CLI", configured_id: "", operationName: "" };
        case 'Power Shell':
          return { name: "Power Shell", type: "Power Shell", configured_id: "", operationName: "" };
        case 'App Utils':
          return { name: "App Utils", type: "App Utils", operationName: "", custom_option: "" };
        default:
          return {};
      }
    },
    executeEventStepAction: function (ctxtSubjectDetails: any, actionType: string, stepType: string) {
      var stepList = ctxtSubjectDetails.stepList;
      var selectedStep = ctxtSubjectDetails.finallyBlockDtls ? ctxtSubjectDetails.finallyBlockDtls : ctxtSubjectDetails.step;
      var childFlowIndex = ctxtSubjectDetails.childFlowIndex;
      var selectedStepindex = ctxtSubjectDetails.stepIndex;
      var childFlowStepDetails: any[];
      if (stepType === 'Child Flow') {
        stepList = this._apiSettings.selectedAPI.metadata.steps.childStepDetails = this._apiSettings.selectedAPI.metadata.steps.childStepDetails || [];
        childFlowStepDetails = stepList;
      }
      switch (actionType) {
        case 'BEFORE':
          stepList.splice(selectedStepindex, 0, this.getNewStepObject(stepType, childFlowStepDetails));
          break;
        case 'AFTER':
          stepList.splice(selectedStepindex + 1, 0, this.getNewStepObject(stepType, childFlowStepDetails));
          break;
        case 'LEFT':
          stepList.splice(selectedStepindex - 1, 0, stepList.splice(selectedStepindex, 1)[0]);
          break;
        case 'RIGHT':
          stepList.splice(selectedStepindex + 1, 0, stepList.splice(selectedStepindex, 1)[0]);
          break;
        case 'FIRST':
          stepList.unshift(stepList.splice(selectedStepindex, 1)[0]);
          break;
        case 'LAST':
          stepList.push(stepList.splice(selectedStepindex, 1)[0]);
          break;
        case 'DELETE':
          if (selectedStep.type === 'ChildFlowStart') {
            this._apiSettings.selectedAPI.metadata.steps.childStepDetails.splice(ctxtSubjectDetails.childFlowIndex, 1);
          } else {
            stepList.splice(selectedStepindex, 1);
          }
          break;
        case 'DISABLE':
          selectedStep.isDisabled = true;
          break;
        case 'ENABLE':
          selectedStep.isDisabled = false;
          break;
        case 'CHILD-FLOW-MOVE-UP':
          var childFlowList = this._apiSettings.selectedAPI.metadata.steps.childStepDetails;
          var selectedObj_metadata = childFlowList.splice(childFlowIndex, 1)[0];
          childFlowList.splice(childFlowIndex - 1, 0, selectedObj_metadata);
          break;
        case 'CHILD-FLOW-MOVE-DOWN':
          var childFlowList = this._apiSettings.selectedAPI.metadata.steps.childStepDetails;
          var selectedObj_metadata = childFlowList.splice(childFlowIndex, 1)[0];
          childFlowList.splice(childFlowIndex + 1, 0, selectedObj_metadata);
          break;
        case 'CHILD-FLOW-MOVE-FIRST':
          var childFlowList = this._apiSettings.selectedAPI.metadata.steps.childStepDetails;
          var selectedObj_metadata = childFlowList.splice(childFlowIndex, 1)[0];
          childFlowList.unshift(selectedObj_metadata);
          break;
        case 'CHILD-FLOW-MOVE-LAST':
          var childFlowList = this._apiSettings.selectedAPI.metadata.steps.childStepDetails;
          var selectedObj_metadata = childFlowList.splice(childFlowIndex, 1)[0];
          childFlowList.push(selectedObj_metadata);
          break;
        case 'MOVE-STEP-TO-PARENT-FLOW':
          var selectedObj_metadata = stepList.splice(selectedStepindex, 1)[0];
          this._apiSettings.selectedAPI.executionStepList.push(selectedObj_metadata);
          break;
        case 'FINALLY-BLOCK':
          this._apiSettings.selectedAPI.metadata.finallyBlockDtls = this.getNewStepObject(stepType);
          break;
        case 'REMOVE-FINALLY-BLOCK':
          delete this._apiSettings.selectedAPI.metadata.finallyBlockDtls;
          this._apiSettings.selectedAPIStep = {};
          break;
        default:
          stepList.push(this.getNewStepObject(stepType, childFlowStepDetails));
          break;
      }
    },
    visibility: {
      disableStep: function (item: any) {
        return !item.step.isDisabled
      },
      enableStep: function (item: any) {
        return item.step.isDisabled;
      },
      disableFinallyBlock: function (item: any) {
        return !item.finallyBlockDtls.isDisabled
      },
      enableFinallyBlock: function (item: any) {
        return item.finallyBlockDtls.isDisabled;
      },
      finallyBlockStep: function (item: any) {
        return !item.finallyBlockDtls;
      },
      addBefore: function (item: any) {
        return item.step.type !== 'ChildFlowStart';
      },
      addAfter: function (item: any) {
        return ["Decision", "FlowConnector"].indexOf(item.step.type) == -1;
      },
      addAfter_Decision: function (item: any) {
        return item.last || item.step.type == "Decision";
      },
      addAfter_FlowConnector: function (item: any) {
        return item.last && item.step.type != "ChildFlowStart";
      },
      moveLeft: function (item: any) {
        let previousStep = item.stepList[item.stepIndex - 1] || {};
        return !item.first && ["Decision", "FlowConnector"].indexOf(item.step.type) == -1 && previousStep.type != "ChildFlowStart";
      },
      moveRight: function (item: any) {
        return !item.last && item.step.type != "ChildFlowStart";
      },
      moveFirst: function (item: any) {
        var stepList = item.stepList;
        if (item.hasOwnProperty("childFlowIndex")) stepList = item.apiSettings.selectedAPI.metadata.steps.childStepDetails[item.childFlowIndex].steps;
        var firstStep = stepList[0] || {};
        return !item.first && ["Decision", "FlowConnector"].indexOf(item.step.type) == -1 && firstStep.type != "ChildFlowStart";
      },
      moveLast: function (item: any) {
        var lastStep = item.stepList[item.stepList.length - 1];
        return !item.last && ["Decision", "FlowConnector"].indexOf(lastStep.type) == -1 && item.step.type != "ChildFlowStart";
      },
      moveChildFlow: function (item: any) {
        return (!item.childFlowFirst && item.step.type == "ChildFlowStart")
          || (!item.childFlowLast && item.step.type == "ChildFlowStart")
          || (!item.childFlowFirst && item.step.type == "ChildFlowStart")
          || (!item.childFlowLast && item.step.type == "ChildFlowStart");
      },
      childFlowMoveUp: function (item: any) {
        return !item.childFlowFirst && item.step.type == "ChildFlowStart";
      },
      childFlowMoveDown: function (item: any) {
        return !item.childFlowLast && item.step.type == "ChildFlowStart";
      },
      childFlowMoveTop: function (item: any) {
        return !item.childFlowFirst && item.step.type == "ChildFlowStart";
      },
      childFlowMoveBottom: function (item: any) {
        return !item.childFlowLast && item.step.type == "ChildFlowStart";
      },
      moveStepToParentFlow: function (item: any) {
        var parentFlowStepList = item.apiSettings.selectedAPI.executionStepList || [];
        var lastStep = parentFlowStepList[parentFlowStepList.length - 1] || {};
        return (((item.apiSettings.selectedAPI.metadata || {}).steps || {}).childStepDetails || [])[item.childFlowIndex] != null && item.step.type != "ChildFlowStart" && ["Decision", "FlowConnector"].indexOf(lastStep.type) == -1;
      }
    }
  }

}